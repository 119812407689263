
import firebase from 'firebase/app'
import 'firebase/database'

export default {

  namespaced: true,
  state: 
  {
    repartidorConectado: {},
  },
  mutations: 
  {
    setRepartidorConectado(state, repartidor)
    {
        state.repartidorConectado = repartidor
    },
    setActualizarRepartidor(state, repartidor)
    {
        state.repartidorConectado = repartidor
    },
  },
  actions: 
  {
    //////////////////////////////////////////////////////////////
    getRepartidoreConectado({ commit }, idRepartidor)
    {
      try 
      {

        const db = firebase.database() 

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups/Repartidores')

        //Obtener el geo fire de repartidores
        referencia.on('child_added', function (snapshot) 
        {

            const data = snapshot.val()

            if(snapshot.key === idRepartidor)
            {
                const detallesRepartidor = {}

                detallesRepartidor.idRepartidor = snapshot.key
                detallesRepartidor.nombreRepartidor = data['data']['Nombre']
                detallesRepartidor.apellidoRepartidor = data['data']['Apellido']
                detallesRepartidor.correoRepartidor = data['data']['Correo']
                detallesRepartidor.telefonoRepartidor = data['data']['Telefono repartidor']
                detallesRepartidor.idVinculada = data['data']['id vinculado']
                detallesRepartidor.vehiculoRepartidor = data['data']['Tipo vehiculo']
                detallesRepartidor.tipoVehiculo = data['data']['Vehiculo']
                detallesRepartidor.estadoRepartidor = data['data']['Estado']
                detallesRepartidor.latRepartidor = data['l'][0]
                detallesRepartidor.lngRepartidor = data['l'][1]
      
                commit('setRepartidorConectado', { ...data, ...detallesRepartidor })
            }
            
        })

      } 
      catch (error) 
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    detectarCambiosRepartidor({ commit }, idRepartidor)
    {
      try 
      {
        
        const db = firebase.database() 

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups/Repartidores')

        //Obtener el geo fire de repartidores
        referencia.on('child_changed', function (snapshot) 
        {

            const data = snapshot.val()

            if(snapshot.key === idRepartidor)
            {
                const detallesRepartidor = {}

                detallesRepartidor.idRepartidor = snapshot.key
                detallesRepartidor.nombreRepartidor = data['data']['Nombre']
                detallesRepartidor.apellidoRepartidor = data['data']['Apellido']
                detallesRepartidor.correoRepartidor = data['data']['Correo']
                detallesRepartidor.telefonoRepartidor = data['data']['Telefono repartidor']
                detallesRepartidor.idVinculada = data['data']['id vinculado']
                detallesRepartidor.vehiculoRepartidor = data['data']['Tipo vehiculo']
                detallesRepartidor.tipoVehiculo = data['data']['Vehiculo']
                detallesRepartidor.estadoRepartidor = data['data']['Estado']
                detallesRepartidor.latRepartidor = data['l'][0]
                detallesRepartidor.lngRepartidor = data['l'][1]
      
                commit('setRepartidorConectado', { ...data, ...detallesRepartidor })
            }
            
        })

      } 
      catch (error) 
      {
        throw new error
      }
    },

  }
}