import firebase from 'firebase/app'
import  { customAlphabet }  from  'nanoid'
import 'firebase/storage'

export const useImagen = () => 
{

    const setImagenn = async(foto) =>
    {
        const urlAlfabeto = 'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict'
      
        const nanoid = customAlphabet (urlAlfabeto, 10) 

        //
        let storageRef = firebase.storage().ref('/media/proj_8guEWRfqYWLLeqH4rGFWae/prueba/' + nanoid(22))
    
        await storageRef.put(foto)

        return storageRef
    }

    const subirImagen = async(foto) => 
    {
        let storageRef = await setImagenn(foto)

        return storageRef.getDownloadURL()
    }

    return { subirImagen }
    
}