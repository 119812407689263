
import firebase from 'firebase/app'
import 'firebase/database'

import { useImagen } from '../../../composables/setImagen'
import { v4 as uuidv4 } from 'uuid'

export default {

  namespaced: true,
  state:
  {
    repartidoresConectados: [],
    solicitudesRepartidores: [],
    detallesMembers: {}, //
    detallesRepartidor: {},
    repartidoresHistorialConexiones: [],
    detallesConexiones: {},
    documentosRepartidores: [],
    listadoDocumentosBajasMedicas: [],
    listadoDocumentosSanciones: [],
    listadoDocumentosAcuerdos: [],
    listadoDocumentosVacaciones: [],
    listadoIdRegistroConexiones: [],
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
    loaderActivo:false
  },
  mutations:
  {
    setReiniciarListadoIdRegistroConexiones(state)
    {
      state.listadoIdRegistroConexiones = []
    },
    setListadoIdRegistroConexiones(state, idRegistro)
    {
      state.listadoIdRegistroConexiones.push(idRegistro)
    },
    setReiniciarDocumentosRepartidores(state)
    {
      state.documentosRepartidores = []
    },
    setListadoDocumentosRepartidores(state, documentos)
    {

      const existeDocumento = state.documentosRepartidores.findIndex(documento => documento.uuid == documentos['uuid'])

      if(existeDocumento === -1)
      {
        state.documentosRepartidores.push(documentos)
      }

    },
    setReiniciarListadoBajasMedicas(state)
    {
      state.listadoDocumentosBajasMedicas = []
    },
    setListadoBajasMedicas(state, documentos)
    {
      const existeDocumento = state.listadoDocumentosBajasMedicas.findIndex(documento => documento.uuid == documentos['uuid'])

      if(existeDocumento === -1)
      {
        state.listadoDocumentosBajasMedicas.push(documentos)
      }
    },
    setReiniciarListadoSanciones(state)
    {
      state.listadoDocumentosSanciones = []
    },
    setListadoSanciones(state, documentos)
    {
      const existeDocumento = state.listadoDocumentosSanciones.findIndex(documento => documento.uuid == documentos['uuid'])

      if(existeDocumento === -1)
      {
        state.listadoDocumentosSanciones.push(documentos)
      }
    },
    setReiniciarListadoAcuerdos(state)
    {
      state.listadoDocumentosAcuerdos = []
    },
    setListadoAcuerdos(state, documentos)
    {
      const existeDocumento = state.listadoDocumentosAcuerdos.findIndex(documento => documento.uuid == documentos['uuid'])

      if(existeDocumento === -1)
      {
        state.listadoDocumentosAcuerdos.push(documentos)
      }
    },
    setReiniciarListadoVacaciones(state)
    {
      state.listadoDocumentosVacaciones = []
    },
    setListadoVacaciones(state, documentos)
    {
      const existeDocumento = state.listadoDocumentosVacaciones.findIndex(documento => documento.uuid == documentos['uuid'])

      if(existeDocumento === -1)
      {
        state.listadoDocumentosVacaciones.push(documentos)
      }
    },

    setReiniciarRepartidoresHistorialConexiones(state)
    {
      state.repartidoresHistorialConexiones = []
    },
    setRepartidoresHistorialConexiones(state, repartidor)
    {
      state.repartidoresHistorialConexiones.push(repartidor)
    },
    setReiniciarRepartidoresConectados(state)
    {
      state.repartidoresConectados = []
    },
    setRepartidoresConectados(state, repartidor)
    {
      state.repartidoresConectados.push(repartidor)
    },
    setNuevosRepartidoresConectados(state, repartidores)
    {

      const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
      const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

      const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
      const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

      state.repartidoresConectados = []

      //Recorrer a todos los repartidores obtenidos de Firebase
      for(const [key, data] of Object.entries(repartidores))
      {
        // console.log(key)
        // console.log(data)

        const detallesRepartidor = {}

        detallesRepartidor.idRepartidor = key
        detallesRepartidor.nombreRepartidor = data['data']['Nombre']
        detallesRepartidor.apellidoRepartidor = data['data']['Apellido']
        detallesRepartidor.correoRepartidor = data['data']['Correo']
        detallesRepartidor.telefonoRepartidor = data['data']['Telefono repartidor']
        detallesRepartidor.idVinculada = data['data']['id vinculado']
        detallesRepartidor.vehiculoRepartidor = data['data']['Tipo vehiculo']
        detallesRepartidor.tipoVehiculo = data['data']['Vehiculo']
        detallesRepartidor.estadoRepartidor = data['data']['Estado']

        //Verificar si el usuario es administrador
        if(administrador)
        {
          state.repartidoresConectados.push({ ...detallesRepartidor, ...data })
        }
        else
        {
          //Verificar si los repartidores estan en la misma zona que el lider
          if(data['data']['id vinculado'] === uid)
          {
            state.repartidoresConectados.push({ ...detallesRepartidor, ...data })
          }
        }

      }

    },
    modificarEstatusRepartidor(state, nuevoEsatus)
    {
      state.detallesRepartidor['Status'] = nuevoEsatus
    },
    actualizarFotoPerfilRepartidor(state, fotoPerfil)
    {
      state.detallesRepartidor['Foto perfil'] = fotoPerfil
    },
    setReiniciarSolicitudesRepartidores(state)
    {
      state.solicitudesRepartidores = []
    },
    setSolicitudesRepartidores(state, repartidor)
    {
      state.solicitudesRepartidores.push(repartidor)
    },
    setDetallesMembers(state, members)
    {
      state.detallesMembers = members
    },
    setDetallesRepartidor(state, repartidor)
    {
      state.detallesRepartidor = repartidor
    },
    setDetallesConexiones(state, conexion)
    {
      state.detallesConexiones = conexion
    },
    /*setDesconectarRepartidor(state, repartidor)
    {
      state.repartidoresNotificaciones = state.repartidoresNotificaciones.filter((notificaciones) => notificaciones['Id repartidor'] !== idRepartidor)
    },*/
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
    setLoaderActivo(state, estado)
    {
      state.loaderActivo =  estado
    }
  },
  actions:
  {
    //////////////////////////////////////////////////////////////
    activarDesactivarLoader({ commit }, estado){
      commit('setLoaderActivo',estado)

    },
    getRepartidoresConectados({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarRepartidoresConectados')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups/Repartidores')

        //Obtener el geo fire de repartidores
        referencia.on('child_added', async function (snapshot)
        {

          const data = snapshot.val()

          const detallesRepartidor = {}

          detallesRepartidor.idRepartidor = snapshot.key
          detallesRepartidor.nombreRepartidor = data['data']['Nombre']
          detallesRepartidor.apellidoRepartidor = data['data']['Apellido']
          detallesRepartidor.correoRepartidor = data['data']['Correo']
          detallesRepartidor.telefonoRepartidor = data['data']['Telefono repartidor']
          detallesRepartidor.idVinculada = data['data']['id vinculado']
          detallesRepartidor.vehiculoRepartidor = data['data']['Tipo vehiculo']
          detallesRepartidor.tipoVehiculo = data['data']['Vehiculo']
          detallesRepartidor.estadoRepartidor = data['data']['Estado']

          //Verificar si el usuario es administrador
          if(administrador)
          {

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresConectados', { ...data, ...detallesRepartidor })
          }
          else
          {
            //Verificar si los repartidores estan en la misma zona que el lider
            if(data['data']['id vinculado'] === uid)
            {

              console.log(snapshot.key)
              console.log(data)

              commit('setRepartidoresConectados', { ...data, ...detallesRepartidor })
            }
          }

        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    detectarRepartidoresConectados({ commit })
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups')

        //Obtener el geo fire de repartidores
        referencia.on('child_changed', async function (snapshot)
        {

          const data = snapshot.val()

          console.log("Cambiado")
          console.log(data)

          commit('setNuevosRepartidoresConectados', data)

        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getSolicitudesRepartidoresAprobados({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarSolicitudesRepartidores')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

        //Obtener repartidores aprobados
        referencia
        .orderByChild('Status')
        .equalTo('Aprobado')
        .on('child_added', async function (snapshot)
        {

          const data = snapshot.val()

          const detallesRepartidor = {}

          detallesRepartidor.idRepartidor = snapshot.key
          detallesRepartidor.nombreRepartidor = data['Nombre']
          detallesRepartidor.apellidoRepartidor = data['Apellido']
          detallesRepartidor.correoRepartidor = data['Correo']
          detallesRepartidor.telefonoRepartidor = data['Telefono repartidor']
          detallesRepartidor.idVinculada = data['id vinculado']
          detallesRepartidor.vehiculoRepartidor = data['Tipo vehiculo']
          detallesRepartidor.tipoVehiculo = data['Vehiculo']

          //Verificar si el usuario es administrador
          if(administrador)
          {

            console.log(snapshot.key)
            console.log(data)

            commit('setSolicitudesRepartidores', { ...data, ...detallesRepartidor })
          }
          else
          {
            //Verificar si los repartidores estan en la misma zona que el lider
            if(data['id vinculado'] === uid)
            {

              console.log(snapshot.key)
              console.log(data)

              commit('setSolicitudesRepartidores', { ...data, ...detallesRepartidor })
            }
          }

        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getSolicitudesRepartidoresRechazados({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarSolicitudesRepartidores')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

        //Obtener repartidores rechazados
        referencia
        .orderByChild('Status')
        .equalTo('Baneado')
        .on('child_added', async function (snapshot)
        {

          const data = snapshot.val()

          const detallesRepartidor = {}

          detallesRepartidor.idRepartidor = snapshot.key
          detallesRepartidor.nombreRepartidor = data['Nombre']
          detallesRepartidor.apellidoRepartidor = data['Apellido']
          detallesRepartidor.correoRepartidor = data['Correo']
          detallesRepartidor.telefonoRepartidor = data['Telefono repartidor']
          detallesRepartidor.idVinculada = data['id vinculado']
          detallesRepartidor.vehiculoRepartidor = data['Tipo vehiculo']
          detallesRepartidor.tipoVehiculo = data['Vehiculo']

          //Verificar si el usuario es administrador
          if(administrador)
          {

            console.log(snapshot.key)
            console.log(data)

            commit('setSolicitudesRepartidores', { ...data, ...detallesRepartidor })
          }
          else
          {
            //Verificar si los repartidores estan en la misma zona que el lider
            if(data['id vinculado'] === uid)
            {

              console.log(snapshot.key)
              console.log(data)

              commit('setSolicitudesRepartidores', { ...data, ...detallesRepartidor })
            }
          }

        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getDetallesMembers({commit}, idRepartidor){
      try {
        const db = firebase.database()
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/' +idRepartidor+'/cloudMessagingTokens')
        referencia.on('value', function (snapshot)
        {
          const data = snapshot.val()
          //console.log('idRepartidor', data, idRepartidor)
          commit('setDetallesMembers', data)
        })
      } catch (error) {
        throw new error
      }

    },
    getDetallesRepartidor({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/' + idRepartidor)

        //Obtener el geo fire de repartidores
        referencia.on('value', function (snapshot)
        {
          const data = snapshot.val()
          commit('setDetallesRepartidor', data)
        })

      }
      catch (error)
      {
        throw new error
      }
    },
    setDesconectarRepartidor({ commit }, repartidor)
    {
      try
      {
        const db = firebase.database()

        //Actualizar informacion de repartidor en solicitud repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${repartidor.idRepartidor}`)
        .update({ ["EN LINEA"]: "NO", ["OCUPADO"]: "NO" })

        //Eliminar repartidor de repartidores disponibles
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Repartidores-disponibles/${repartidor.idVinculada}/zona/${repartidor.idRepartidor}`)
        .remove()

        //Eliminar repartidor de Geofire
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae//geoFireGroups/Repartidores/${repartidor.idRepartidor}`)
        .remove()

        const alerta =
        {
          status: true,
          titulo: 'Repartidor desconectado',
          mensaje: 'Se ha desconectado a ' + repartidor.nombreRepartidor + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    setNuevoEstatusRepartidor({ commit }, repartidor)
    {
      try
      {

        const db = firebase.database()

        //Actualizar informacion de repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${repartidor.idRepartidor}`)
        .update({ ['Status']: repartidor.estatus, ['INDEX status']: repartidor.index })

        //Actualizar members de repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/${repartidor.idRepartidor}/customData`)
        .update({ ['status']: repartidor.estatusMembers })

        const alerta =
        {
          status: true,
          titulo: 'Repartidor ' + (repartidor.estatus).toLowerCase(),
          mensaje: 'Se ha ' + (repartidor.estatus).toLowerCase() + ' a ' + repartidor.nombreRepartidor + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('modificarEstatusRepartidor', repartidor.estatus)

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    setModificarDetallesRepartidor({ commit }, repartidor)
    {
      try
      {

        const db = firebase.database()

        //Actualizar informacion de repartidor de solicitud repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${repartidor.idRepartidor}`)
        .update({ ['Placas']: repartidor['Placas'], ['Vehiculo']: repartidor['Vehiculo'], ['Tipo vehiculo']: repartidor['Tipo vehiculo'] })

        //Actualizar informacion de repartidor de custom data
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/${repartidor.idRepartidor}/customData`)
        .update({ ['Tipo vehiculo']: repartidor['Vehiculo'] })

        const alerta =
        {
          status: true,
          titulo: 'Repartidor modificado',
          mensaje: 'Se ha modificado a ' + repartidor.nombreRepartidor + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    async updatedFotoPerfilRepartidor({ commit }, repartidor)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        //Subir imagen a storage
        const imagenPerfil = {
          photoURL: await subirImagen(repartidor.fotoRepartidor),
        }

        //Actualizar informacion de repartidor en solicitudes
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${repartidor.idRepartidor}`)
        .update({ ['Foto perfil']: imagenPerfil.photoURL })

        //Actualizar informacion de repartidor en members
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/${repartidor.idRepartidor}`)
        .update({ ['photoURL']: imagenPerfil.photoURL })

        commit('actualizarFotoPerfilRepartidor', imagenPerfil.photoURL)

        const alerta =
        {
          status: true,
          titulo: 'Foto de perfil actualizada',
          mensaje: 'Se ha actualizado la foto de perfil de ' + repartidor.nombreRepartidor + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    getRepartidoresHistorialConectados({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarRepartidoresHistorialConexiones')
        commit('setReiniciarListadoIdRegistroConexiones')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        //Verificar si el usuario es administrador
        if(administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('estatusConexion')
          .equalTo('Conectado')
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            const fechaConexion = new Date(data['fechaConexion'])
            const fechaDesconexion = new Date(data['fechaDesconexion'])

            data['timestampConexion'] = data['fechaConexion']

            if(!isNaN(fechaConexion.getTime()))
            {
              data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
            }
            else
            {
              data['fechaConexion'] = '-'
            }

            if(!isNaN(fechaDesconexion.getTime()))
            {
              data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
            }
            else
            {
              data['fechaDesconexion'] = '-'
            }

            if(data['correo'] === undefined)
            {
              data['correo'] = '-'
            }

            if(data['nombreRepartidor'] === undefined)
            {
              data['nombreRepartidor'] = '-'
            }

            //
            data.idRegistro = snapshot.key

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresHistorialConexiones', data)

            commit('setListadoIdRegistroConexiones', snapshot.key)

          })
        }

        //Verificar si los repartidores estan en la misma zona que el lider
        if(!administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('idVinculadaEstatus')
          .equalTo(uid + 'Conectado')
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            const fechaConexion = new Date(data['fechaConexion'])
            const fechaDesconexion = new Date(data['fechaDesconexion'])

            data['timestampConexion'] = data['fechaConexion']

            if(!isNaN(fechaConexion.getTime()))
            {
              data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
            }
            else
            {
              data['fechaConexion'] = '-'
            }

            if(!isNaN(fechaDesconexion.getTime()))
            {
              data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
            }
            else
            {
              data['fechaDesconexion'] = '-'
            }

            if(data['correo'] === undefined)
            {
              data['correo'] = '-'
            }

            if(data['nombreRepartidor'] === undefined)
            {
              data['nombreRepartidor'] = '-'
            }

            //
            data.idRegistro = snapshot.key

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresHistorialConexiones', data)

            commit('setListadoIdRegistroConexiones', snapshot.key)

          })
        }

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getRepartidoresHistorialDesconectados({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarRepartidoresHistorialConexiones')
        commit('setReiniciarListadoIdRegistroConexiones')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        //Verificar si el usuario es administrador
        if(administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('estatusConexion')
          .equalTo('Desconectado')
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            const fechaConexion = new Date(data['fechaConexion'])
            const fechaDesconexion = new Date(data['fechaDesconexion'])

            data['timestampDesconexion'] = data['fechaDesconexion']

            if(!isNaN(fechaConexion.getTime()))
            {
              data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
            }
            else
            {
              data['fechaConexion'] = '-'
            }

            if(!isNaN(fechaDesconexion.getTime()))
            {
              data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
            }
            else
            {
              data['fechaDesconexion'] = '-'
            }

            if(data['correo'] === undefined)
            {
              data['correo'] = '-'
            }

            if(data['nombreRepartidor'] === undefined)
            {
              data['nombreRepartidor'] = '-'
            }

            //
            data.idRegistro = snapshot.key

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresHistorialConexiones', data)

            commit('setListadoIdRegistroConexiones', snapshot.key)

          })
        }

        //Verificar si los repartidores estan en la misma zona que el lider
        if(!administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('idVinculadaEstatus')
          .equalTo(uid + 'Desconectado')
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            const fechaConexion = new Date(data['fechaConexion'])
            const fechaDesconexion = new Date(data['fechaDesconexion'])

            data['timestampDesconexion'] = data['fechaDesconexion']

            if(!isNaN(fechaConexion.getTime()))
            {
              data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
            }
            else
            {
              data['fechaConexion'] = '-'
            }

            if(!isNaN(fechaDesconexion.getTime()))
            {
              data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
            }
            else
            {
              data['fechaDesconexion'] = '-'
            }

            if(data['correo'] === undefined)
            {
              data['correo'] = '-'
            }

            if(data['nombreRepartidor'] === undefined)
            {
              data['nombreRepartidor'] = '-'
            }

            //
            data.idRegistro = snapshot.key

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresHistorialConexiones', data)

            commit('setListadoIdRegistroConexiones', snapshot.key)

          })
        }

      }
      catch (error)
      {
        throw new error
      }
    },
    getDetallesConexiones({ commit }, idConexion)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor/' + idConexion)

        //Obtener el historial de conexiones del repartidor
        referencia.on('value', function (snapshot)
        {
          const data = snapshot.val()

          const fechaConexion = new Date(data['fechaConexion'])
          const fechaDesconexion = new Date(data['fechaDesconexion'])

          if(!isNaN(fechaConexion.getTime()))
          {
            data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + " a las " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
          }
          else
          {
            data['fechaConexion'] = '-'
          }

          if(!isNaN(fechaDesconexion.getTime()))
          {
            data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + " a las " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
          }
          else
          {
            data['fechaDesconexion'] = '-'
          }

          if(data['correoRepartidor'] === undefined)
          {
            data['correoRepartidor'] = '-'
          }

          if(data['nombreRepartidor'] === undefined)
          {
            data['nombreRepartidor'] = '-'
          }

          commit('setDetallesConexiones', data)
        })

      }
      catch (error)
      {
        throw new error
      }
    },
    /*getDetalleConexionRepartidor({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        commit('setReiniciarRepartidoresHistorialConexiones')

        //Obtener el historial de conexiones del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          const fechaConexion = new Date(data['fechaConexion'])
          const fechaDesconexion = new Date(data['fechaDesconexion'])

          if(!isNaN(fechaConexion.getTime()))
          {
            data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
          }
          else
          {
            data['fechaConexion'] = '-'
          }

          if(!isNaN(fechaDesconexion.getTime()))
          {
            data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
          }
          else
          {
            data['fechaDesconexion'] = '-'
          }

          if(data['correoRepartidor'] === undefined)
          {
            data['correoRepartidor'] = '-'
          }

          if(data['nombreRepartidor'] === undefined)
          {
            data['nombreRepartidor'] = '-'
          }

          data.idRegistro = snapshot.key

          commit('setRepartidoresHistorialConexiones', data)
        })

      }
      catch (error)
      {
        throw new error
      }
    },*/


    //////////////////////////////////////////////////////////////
    getDocumentosRepartidor({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/documentos-oficiales')

        commit('setReiniciarDocumentosRepartidores')

        //Obtener los documentos del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          //Convertir fecha de agregado
          const fechaAgregado = new Date(data['fecha'])

          data.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoDocumentosRepartidores', data)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    async setDocumentosRepartidores({ commit }, informacionDocumentos)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        for(const documento of informacionDocumentos)
        {
          const idDocumento = uuidv4()

          //Subir archivo a storage
          const urlArchivo = {
            photoURL: await subirImagen(documento['archivoTemporal']),
          }

          delete documento['archivoTemporal']

          documento.uuid = idDocumento
          documento.archivo = urlArchivo.photoURL

          //Guardar archivos en coleccion
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/documentos-oficiales/${ idDocumento }`)
          .set(documento)

          //Convertir fecha de agregado
          const fechaAgregado = new Date(documento['fecha'])

          documento.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoDocumentosRepartidores', documento)

          console.log("-----")
          console.log(documento)
        }

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'Documentos guardados',
          mensaje: 'Los archivos han sido guardados exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'ERROR',
          mensaje: 'Ha ocurrido un error: ' + error + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('setEstatusSolicitud', 'error')
      }
    },
    //////////////////////////////////////////////////////////////
    async setDocumentosBajasMedicas({ commit }, informacionBajasMedicas)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        for(const documento of informacionBajasMedicas)
        {

          const idDocumento = uuidv4()

          //Subir archivo a storage
          const urlArchivo = {
            photoURL: await subirImagen(documento['archivoTemporal']),
          }

          delete documento['archivoTemporal']

          documento.uuid = idDocumento
          documento.archivo = urlArchivo.photoURL

          //Guardar archivos en coleccion
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/bajas-medicas/${ idDocumento }`)
          .set(documento)

          //Convertir fecha de agregado
          const fechaAgregado = new Date(documento['fecha'])
          const fechaInicio = new Date(documento['fechaInicio'])
          const fechaTermino = new Date(documento['fechaFin'])

          documento.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()
          documento.periodoFechas = "Del " + fechaInicio.getDate() + "/" + (fechaInicio.getMonth() + 1) + "/" + fechaInicio.getFullYear() + "  al  " + fechaTermino.getDate() + "/" + (fechaTermino.getMonth() + 1) + "/" + fechaTermino.getFullYear()

          //Guardar documentos de repartidor
          commit('setListadoBajasMedicas', documento)

          console.log("-----")
          console.log(documento)
        }

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'Baja médica agregada',
          mensaje: 'Los archivos han sido guardados exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'ERROR',
          mensaje: 'Ha ocurrido un error: ' + error + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('setEstatusSolicitud', 'error')
      }
    },
    getBajasMedicasRepartidores({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/bajas-medicas')

        commit('setReiniciarListadoBajasMedicas')

        //Obtener los documentos del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          //Convertir fecha de agregado
          const fechaAgregado = new Date(data['fecha'])
          const fechaInicio = new Date(data['fechaInicio'])
          const fechaTermino = new Date(data['fechaFin'])

          data.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()
          data.periodoFechas = "Del " + fechaInicio.getDate() + "/" + (fechaInicio.getMonth() + 1) + "/" + fechaInicio.getFullYear() + "  al  " + fechaTermino.getDate() + "/" + (fechaTermino.getMonth() + 1) + "/" + fechaTermino.getFullYear()

          //Guardar documentos de repartidor
          commit('setListadoBajasMedicas', data)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    async setDocumentosSanciones({ commit }, informacionSanciones)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        for(const documento of informacionSanciones)
        {

          const idDocumento = uuidv4()

          //Subir archivo a storage
          const urlArchivo = {
            photoURL: await subirImagen(documento['archivoTemporal']),
          }

          delete documento['archivoTemporal']

          documento.uuid = idDocumento
          documento.archivo = urlArchivo.photoURL

          //Guardar archivos en coleccion
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/sanciones/${ idDocumento }`)
          .set(documento)

          //Convertir fecha de agregado
          const fechaAgregado = new Date(documento['fecha'])

          documento.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoSanciones', documento)

          console.log("-----")
          console.log(documento)
        }

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'Baja médica agregada',
          mensaje: 'Los archivos han sido guardados exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'ERROR',
          mensaje: 'Ha ocurrido un error: ' + error + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('setEstatusSolicitud', 'error')
      }
    },
    getSancionesRepartidores({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/sanciones')

        commit('setReiniciarListadoSanciones')

        //Obtener los documentos del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          //Convertir fecha de agregado
          const fechaAgregado = new Date(data['fecha'])

          data.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoSanciones', data)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    async setDocumentosAcuerdos({ commit }, informacionAcuerdos)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        for(const documento of informacionAcuerdos)
        {

          const idDocumento = uuidv4()

          //Subir archivo a storage
          const urlArchivo = {
            photoURL: await subirImagen(documento['archivoTemporal']),
          }

          delete documento['archivoTemporal']

          documento.uuid = idDocumento
          documento.archivo = urlArchivo.photoURL

          //Guardar archivos en coleccion
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/acuerdos/${ idDocumento }`)
          .set(documento)

          //Convertir fecha de agregado
          const fechaAgregado = new Date(documento['fecha'])

          documento.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoAcuerdos', documento)

          console.log("-----")
          console.log(documento)
        }

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'Acuerdo agregado',
          mensaje: 'Los archivos han sido guardados exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'ERROR',
          mensaje: 'Ha ocurrido un error: ' + error + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('setEstatusSolicitud', 'error')
      }
    },
    getAcuerdosRepartidores({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/acuerdos')

        commit('setReiniciarListadoAcuerdos')

        //Obtener los documentos del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          //Convertir fecha de agregado
          const fechaAgregado = new Date(data['fecha'])

          data.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()

          //Guardar documentos de repartidor
          commit('setListadoAcuerdos', data)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    async setDocumentosVacaciones({ commit }, informacionVacaciones)
    {
      try
      {

        const db = firebase.database()

        const { subirImagen } = useImagen()

        for(const documento of informacionVacaciones)
        {

          const idDocumento = uuidv4()

          //Subir archivo a storage
          const urlArchivo = {
            photoURL: await subirImagen(documento['archivoTemporal']),
          }

          delete documento['archivoTemporal']

          documento.uuid = idDocumento
          documento.archivo = urlArchivo.photoURL

          //Guardar archivos en coleccion
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/vacaciones/${ idDocumento }`)
          .set(documento)

          //Convertir fecha de agregado
          const fechaAgregado = new Date(documento['fecha'])
          const fechaInicio = new Date(documento['fechaInicio'])
          const fechaTermino = new Date(documento['fechaFin'])

          documento.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()
          documento.periodoFechas = "Del " + fechaInicio.getDate() + "/" + (fechaInicio.getMonth() + 1) + "/" + fechaInicio.getFullYear() + "  al  " + fechaTermino.getDate() + "/" + (fechaTermino.getMonth() + 1) + "/" + fechaTermino.getFullYear()

          //Guardar documentos de repartidor
          commit('setListadoVacaciones', documento)

          console.log("-----")
          console.log(documento)
        }

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'Vacaciones agregadas',
          mensaje: 'Los archivos han sido guardados exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {

        //Guardar informacion de alerta
        const alerta =
        {
          status: true,
          titulo: 'ERROR',
          mensaje: 'Ha ocurrido un error: ' + error + '.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        commit('setEstatusSolicitud', 'error')
      }
    },
    getVacacionesRepartidores({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/vacaciones')

        commit('setReiniciarListadoVacaciones')

        //Obtener los documentos del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()

          //Convertir fecha de agregado
          const fechaAgregado = new Date(data['fecha'])
          const fechaInicio = new Date(data['fechaInicio'])
          const fechaTermino = new Date(data['fechaFin'])

          data.fecha = fechaAgregado.getDate() + "/" + (fechaAgregado.getMonth() + 1) + "/" + fechaAgregado.getFullYear() + "   " + fechaAgregado.getHours() + ":" + fechaAgregado.getMinutes() + ":" + fechaAgregado.getSeconds()
          data.periodoFechas = "Del " + fechaInicio.getDate() + "/" + (fechaInicio.getMonth() + 1) + "/" + fechaInicio.getFullYear() + "  al  " + fechaTermino.getDate() + "/" + (fechaTermino.getMonth() + 1) + "/" + fechaTermino.getFullYear()

          //Guardar documentos de repartidor
          commit('setListadoVacaciones', data)

        })

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    filtradoListadoConexion({ commit }, informacionConexion)
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarRepartidoresHistorialConexiones')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        //Verificar si el usuario es administrador
        if(administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('fecha')
          .startAt(informacionConexion.fechaInicio)
          .endAt(informacionConexion.fechaFinal)
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            if(data['estatusConexion'] === informacionConexion.estatus)
            {
              const fechaConexion = new Date(data['fechaConexion'])
              const fechaDesconexion = new Date(data['fechaDesconexion'])

              if(informacionConexion.estatus === 'Desconectado')
              {
                data['timestampDesconexion'] = data['fechaDesconexion']
              }

              if(informacionConexion.estatus === 'Conectado')
              {
                data['timestampConexion'] = data['fechaConexion']
              }

              if(!isNaN(fechaConexion.getTime()))
              {
                data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
              }
              else
              {
                data['fechaConexion'] = '-'
              }

              if(!isNaN(fechaDesconexion.getTime()))
              {
                data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
              }
              else
              {
                data['fechaDesconexion'] = '-'
              }

              if(data['correo'] === undefined)
              {
                data['correo'] = '-'
              }

              if(data['nombreRepartidor'] === undefined)
              {
                data['nombreRepartidor'] = '-'
              }

              //
              data.idRegistro = snapshot.key

              console.log(snapshot.key)
              console.log(data)

              commit('setRepartidoresHistorialConexiones', data)

              //commit('setListadoIdRegistroConexiones', snapshot.key)
            }

          })
        }

        //Verificar si los repartidores estan en la misma zona que el lider
        if(!administrador)
        {
          //Obtener al historial de repartidores conectados
          referencia.orderByChild('idVinculadaEstatusTimestamp')
          .startAt(uid + informacionConexion.estatus + informacionConexion.fechaInicio)
          .endAt(uid + informacionConexion.estatus + informacionConexion.fechaFinal)
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            const fechaConexion = new Date(data['fechaConexion'])
            const fechaDesconexion = new Date(data['fechaDesconexion'])

            if(informacionConexion.estatus === 'Desconectado')
            {
              data['timestampDesconexion'] = data['fechaDesconexion']
            }

            if(informacionConexion.estatus === 'Conectado')
            {
              data['timestampConexion'] = data['fechaConexion']
            }

            if(!isNaN(fechaConexion.getTime()))
            {
              data['fechaConexion'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()
            }
            else
            {
              data['fechaConexion'] = '-'
            }

            if(!isNaN(fechaDesconexion.getTime()))
            {
              data['fechaDesconexion'] = fechaDesconexion.getDate() + "/" + (fechaDesconexion.getMonth() + 1) + "/" + fechaDesconexion.getFullYear() + "   " + fechaDesconexion.getHours() + ":" + fechaDesconexion.getMinutes() + ":" + fechaDesconexion.getSeconds()
            }
            else
            {
              data['fechaDesconexion'] = '-'
            }

            if(data['correo'] === undefined)
            {
              data['correo'] = '-'
            }

            if(data['nombreRepartidor'] === undefined)
            {
              data['nombreRepartidor'] = '-'
            }

            //
            data.idRegistro = snapshot.key

            console.log(snapshot.key)
            console.log(data)

            commit('setRepartidoresHistorialConexiones', data)

            //commit('setListadoIdRegistroConexiones', snapshot.key)

          })
        }

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getDetalleConexionRepartidor({ commit, dispatch }, repartidor)
    {
      try
      {
        dispatch('activarDesactivarLoader',true);

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        commit('setReiniciarRepartidoresHistorialConexiones')

        //Obtener el historial de conexiones del repartidor
        referencia.orderByChild('idRepartidorTimestamp')
        .startAt(repartidor.idRepartidor + repartidor.fechaInicio)
        .endAt(repartidor.idRepartidor + repartidor.fechaFinal)
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val();

          const fechaConexion = new Date(data['fecha'])

          data['fechaConvertida'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()

          data.idRegistro = snapshot.key

          commit('setRepartidoresHistorialConexiones', data)

        })

        referencia.orderByChild('idRepartidorTimestamp')
        .startAt(repartidor.idRepartidor + repartidor.fechaInicio)
        .endAt(repartidor.idRepartidor + repartidor.fechaFinal)
        .once('value', () => {
          dispatch('activarDesactivarLoader',false);
        })

      }
      catch (error)
      {
        dispatch('activarDesactivarLoader',false);
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getDetalleCompletoConexionRepartidor({ commit }, idRepartidor)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/HistorialConexionesRepartidor')

        commit('setReiniciarRepartidoresHistorialConexiones')

        //Obtener el historial de conexiones del repartidor
        referencia.orderByChild('idRepartidor')
        .equalTo(idRepartidor)
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          const fechaConexion = new Date(data['fecha'])

          data['fechaConvertida'] = fechaConexion.getDate() + "/" + (fechaConexion.getMonth() + 1) + "/" + fechaConexion.getFullYear() + "   " + fechaConexion.getHours() + ":" + fechaConexion.getMinutes() + ":" + fechaConexion.getSeconds()

          data.idRegistro = snapshot.key

          commit('setRepartidoresHistorialConexiones', data)
        })

      }
      catch (error)
      {
        throw new error
      }
    },
    //////////////////////////////////////////////////////////////
    getSolicitudesRepartidores({ commit }, idVinculada)
    {
      try
      {

        const db = firebase.database()

        commit('setReiniciarSolicitudesRepartidores')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

        //Obtener repartidores aprobados
        referencia
        .orderByChild('INDEX status')
        .equalTo(idVinculada + '+Aprobado')
        .on('child_added', async function (snapshot)
        {

          const data = snapshot.val()

          const detallesRepartidor = {}

          detallesRepartidor.idRepartidor = snapshot.key
          detallesRepartidor.nombreRepartidor = data['Nombre']
          detallesRepartidor.apellidoRepartidor = data['Apellido']
          detallesRepartidor.correoRepartidor = data['Correo']
          detallesRepartidor.telefonoRepartidor = data['Telefono repartidor']
          detallesRepartidor.idVinculada = data['id vinculado']
          detallesRepartidor.vehiculoRepartidor = data['Tipo vehiculo']
          detallesRepartidor.tipoVehiculo = data['Vehiculo']

          // console.log(snapshot.key)
          // console.log(data)

          commit('setSolicitudesRepartidores', { ...data, ...detallesRepartidor })

        })

      }
      catch (error)
      {
        throw new error
      }
    },
  }
}