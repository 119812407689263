
import firebase from 'firebase/app'
import 'firebase/database'

//import { v4 as uuidv4 } from 'uuid'

export default {

    namespaced: true,
    state:
    {
      idSolicitud: '',
      preciosSolicitudes: {},
      solicitudesFinalizadas: [],
      solicitudesProgramadas: [],
      solicitudesEnCurso: [],
      solicitudesRepartidores: [],
      estatusSolicitud: '',
      solicitudesIniciales:[],
      mensajeAlertaSolicitudes: {
        status: false,
        titulo: '',
        mensaje: '',
        variant: '',
        toaster: '',
      },
    },
    mutations:
    {
      setIdSolicitud(state, idSolicitud)
      {
        state.idSolicitud = idSolicitud
      },
      setPrecio(state, precios)
      {
        state.preciosSolicitudes = precios
      },
      setReiniciarSolicitudes(state)
      {
        state.solicitudesFinalizadas = []
        state.solicitudesProgramadas = []
        state.solicitudesEnCurso = []
      },
      setSolicitudesFinalizadas(state, solicitud)
      {
        state.solicitudesFinalizadas.push(solicitud)
      },
      setSolicitudesProgramadas(state, solicitud)
      {
        state.solicitudesProgramadas.push(solicitud)
      },
      setSolicitudesEnCurso(state, solicitud)
      {
        state.solicitudesEnCurso.push(solicitud)
        // console.log("Aquí se generan las solicitudes en curso")
        console.log(state.solicitudesEnCurso)
      },
      setNuevasSolicitudesEnCurso(state, solicitud)
      {
        const arrayTemporal = state.solicitudesEnCurso

        arrayTemporal.push(solicitud)

        let hash = {}
        state.solicitudesEnCurso = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

      },
      setNuevasSolicitudesProgramadas(state, solicitud)
      {
        const arrayTemporal = state.solicitudesProgramadas

        arrayTemporal.push(solicitud)

        let hash = {}
        state.solicitudesProgramadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

      },
      setNuevasSolicitudesFinalizadas(state, solicitud)
      {
        const arrayTemporal = state.solicitudesFinalizadas

        arrayTemporal.push(solicitud)

        let hash = {}
        state.solicitudesFinalizadas = arrayTemporal.filter(solicitudes => hash[solicitudes.idEntrega] ? false : hash[solicitudes.idEntrega] = true)

      },
      modificarSolicitudesEnCurso(state, solicitud)
      {
        console.log("Este modifica la tabla (renderización)")
        console.log(solicitud)
        state.solicitudesEnCurso.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            listado.estatusSolicitud = solicitud.estatus
            listado.estatusNuevoSolicitud = solicitud.estatusNuevoSolicitud
            listado.nombreRepartidor = solicitud.nombreRepartidor
            listado.vehiculoRepartidor = solicitud.vehiculoRepartidor
            listado.fechaEnCurso = solicitud.fechaEnCurso
            listado.fechaAceptado = solicitud.fechaAceptado
            listado.fechaEnEstablecimiento = solicitud.fechaEnEstablecimiento
          }
        })

      },
      modificarSolicitudesProgramadas(state, solicitud)
      {
        state.solicitudesProgramadas.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            // cambio de variable para actualizar "Notificado->Aceptado" en nuevo etiquetas
            listado.estatusNuevoSolicitud = solicitud.estatus
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idEntrega !== solicitud.id)
      },
      modificarSolicitudesFinalizadas(state, solicitud)
      {

        state.solicitudesFinalizadas.map(function (listado) {
          if(listado.idEntrega === solicitud.id)
          {
            listado.estatusNuevoSolicitud = solicitud.estatus
          }
        })

        state.solicitudesEnCurso = state.solicitudesEnCurso.filter((item) => item.idEntrega !== solicitud.id)
      },
      setSolicitudesRepartidores(state, repartidor)
      {
        state.solicitudesRepartidores.push(repartidor)
      },
      setEstatusSolicitud(state, status)
      {
        state.estatusSolicitud = status
        console.log(state.estatusSolicitud)
      },
      setMensajeAlerta(state, alerta)
      {
        state.mensajeAlertaSolicitudes.status = alerta.status
        state.mensajeAlertaSolicitudes.titulo = alerta.titulo
        state.mensajeAlertaSolicitudes.mensaje = alerta.mensaje
        state.mensajeAlertaSolicitudes.variant = alerta.variant
        state.mensajeAlertaSolicitudes.toaster = alerta.toaster
      },
      setSolicitudesIniciales(state, solicitud)
      {
        state.solicitudesIniciales.push(solicitud)
      },
    },
    actions:
    {
      setMessageAlertAction({ commit },{alertData}){
        commit('setMensajeAlerta',alertData)
      },
      //////////////////////////////////////////////////////////////
      guardarSolicitudPedido({ commit }, pedido)
      {

        try
        {

          const db = firebase.database()
          const uid = pedido[0].idDireccionCliente !== '' && pedido[0].idDireccionCliente !== undefined ? pedido[0].idDireccionCliente : firebase.auth().currentUser.uid
          //const idFirebaseSolicitud = uuidv4()
          //const idSolicitud = "OnTrack" + "-" + +new Date()

          const estatusSolicitud = pedido[0].estatusSolicitud === 'Conectando' ? 'Notificado' : 'Programado'

          const idSolicitud = pedido[0].idSolicitud

          delete pedido[0].idSolicitud
          delete pedido[0].idDireccionCliente

          /* /// Guardar solicitudes  /// */
          pedido.forEach(function(solicitud)
          {

            ///// Guardar solicitud de recoleccion
            if(solicitud.solictudRecoleccion)
            {

              delete solicitud.tipoVehiculo
              delete solicitud.solictudRecoleccion

              Object.assign(solicitud, { clienteID: uid })
              Object.assign(solicitud, { estatusNuevoSolicitud: estatusSolicitud })
              Object.assign(solicitud, { ['zipcode_clienteID_Timestamp']: solicitud.zipcode + '_' + solicitud.clienteID + '_' + solicitud.fechaSolicitud })
              Object.assign(solicitud, { idSolicitud: idSolicitud })
              Object.assign(solicitud, { clienteID_Estatus: uid + '_' + solicitud.estatusSolicitud })
              Object.assign(solicitud, { idVinculada_Timestamp: solicitud.idVinculada + '_' + solicitud.fechaSolicitud })
              Object.assign(solicitud, { clienteID_Timestamp: solicitud.clienteID + '_' + solicitud.fechaSolicitud })

              db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/' + idSolicitud)
              .set( { ...solicitud, solicitudOrigen: 'Web' } )

              console.log("recoleccion:" + { ...solicitud, solicitudOrigen: 'Web' })
            }
            else
            {

              ///// Guardar solicitud de entrega
              solicitud.forEach(function(entrega)
              {
                const idEntrega = entrega.idEntrega === undefined || entrega.idEntrega === '' ? "OnTrack" + "-" + +new Date() : entrega.idEntrega

                Object.assign(entrega, { idSolicitud: idSolicitud })
                Object.assign(entrega, { idEntrega: idEntrega })
                Object.assign(entrega, { estatusNuevoSolicitud: estatusSolicitud })

                delete entrega.marcadorMapa
                delete entrega.validarMarcador
                //delete entrega.direccionCompleta
                delete entrega.solictudRecoleccion
                delete entrega.distanciaEntrega
                delete entrega.duracionEntrega
                delete entrega.empresaEntrega

                //
                Object.assign(entrega, { pisoEntrega: entrega.numeroPiso, puertaEntrega: entrega.numeroPuerta })
                Object.assign(entrega, { estatusEntrega: pedido[0].estatusSolicitud } )

                delete entrega.numeroPiso
                delete entrega.numeroPuerta
                delete entrega.hintEntrega

                const ordenEntrega = "-ordenEntrega-" + entrega.ordenEntrega

                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/' + idSolicitud + '/solicitud entrega/' + idEntrega + ordenEntrega)
                .set( entrega )

                console.log( entrega )
              })
            }
            console.log(idSolicitud)
          })

          const alerta =
          {
            status: true,
            titulo: 'Guardado',
            mensaje: 'Su solicitud ha sido enviada.',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          }

          commit('setMensajeAlerta', alerta)
          commit('setIdSolicitud', idSolicitud)

        } catch (error)
        {
          const alerta =
          {
            status: false,
            titulo: 'Ha ocurrido un error',
            mensaje: 'ERROR: ' + error,
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          }

          commit('setMensajeAlerta', alerta)
        }

      },
      async getPreciosSolicitudes({ commit })
      {
        try
        {

          const db = firebase.database()

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Precios-stuart')

          /* ////// Obtener listado de direcciones ////// */
          await referencia.once('value', function(snapshot)
          {
            const data = snapshot.val()

            console.log(data)

            commit('setPrecio', data)

          })

        }
        catch (error)
        {
          alert(error)
        }
      },
      //////////////////////////////////////////////////////////////
      getSolicitudes({ commit })
      {
        try
        {
          const db = firebase.database()
          const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
          const uid = datosUsuario.uid

          commit('setEstatusSolicitud', 'cargando')
          commit('setReiniciarSolicitudes')

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

          referencia.orderByChild('clienteID')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            for (let id in data['solicitud entrega'])
            {

              const estatusSolicitud = data['solicitud entrega'][id].estatusEntrega.toLowerCase()
              const estatusNuevoSolicitud = data['solicitud entrega'][id].estatusNuevoSolicitud.toLowerCase()

              data.timestampSolicitud = data['fechaSolicitud']

              if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
              {
                const date = new Date(data['fechaSolicitud'])

                //data['solicitud entrega'][id].idEntrega = id
                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setSolicitudesFinalizadas', data['solicitud entrega'][id])
                commit('setSolicitudesIniciales', data['solicitud entrega'][id])
                // console.log(data['solicitud entrega'][id])
              }

              if (estatusSolicitud === 'programado')
              {
                const date = new Date(data['fechaSolicitud'])

                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setSolicitudesProgramadas', data['solicitud entrega'][id])
                console.log(data['solicitud entrega'][id])
              }

              if (estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusSolicitud ===  'recolectando')
              {
                const date = new Date(data['fechaSolicitud'])

                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].estatusNuevoSolicitud = estatusNuevoSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setSolicitudesEnCurso', data['solicitud entrega'][id])
                // console.log(data['solicitud entrega'][id])
              }

            }

          })

        }
        catch (error)
        {
          commit('setEstatusSolicitud', 'error')
          throw error
        }
        finally
        {
          commit('setEstatusSolicitud', 'finalizado')
        }

      },
      getSolicitudesFiltradas({ commit }, informacionSolicitud)
      {
        try
        {

          const db = firebase.database()
          const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
          const uid = datosUsuario.uid

          commit('setEstatusSolicitud', 'cargando')
          commit('setReiniciarSolicitudes')

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

          referencia.orderByChild('fechaAceptado')
          .startAt(informacionSolicitud.fechaInicio)
          .endAt(informacionSolicitud.fechaFinal)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val();

            if(data['clienteID'] == uid){
              for (let id in data['solicitud entrega'])
              {
                const estatusSolicitud = data['solicitud entrega'][id].estatusEntrega.toLowerCase()
                const estatusNuevoSolicitud = data['solicitud entrega'][id].estatusNuevoSolicitud.toLowerCase()

                data.timestampSolicitud = data['fechaSolicitud']

                if (estatusSolicitud === 'finalizado' || estatusSolicitud === 'cancelado')
                {
                  const date = new Date(data['fechaSolicitud'])

                  //data['solicitud entrega'][id].idEntrega = id
                  data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                  data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                  data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                  data['solicitud entrega'][id].idVinculada = data['idVinculada']
                  data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                  data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                  commit('setSolicitudesFinalizadas', data['solicitud entrega'][id])
                  // console.log(data['solicitud entrega'][id])
                }

                if (estatusSolicitud === 'programado')
                {
                  const date = new Date(data['fechaSolicitud'])

                  data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                  data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                  data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                  data['solicitud entrega'][id].idVinculada = data['idVinculada']
                  data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                  data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                  commit('setSolicitudesProgramadas', data['solicitud entrega'][id])
                  console.log(data['solicitud entrega'][id])
                }

                if (estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusSolicitud ===  'recolectando')
                {
                  const date = new Date(data['fechaSolicitud'])

                  data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                  data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                  data['solicitud entrega'][id].estatusNuevoSolicitud = estatusNuevoSolicitud
                  data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                  data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                  data['solicitud entrega'][id].idVinculada = data['idVinculada']
                  data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                  data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                  commit('setSolicitudesEnCurso', data['solicitud entrega'][id])
                  console.log(data['solicitud entrega'][id])
                }

              }
            }

          })

          referencia.once('value', () => {
            commit('setEstatusSolicitud', 'finalizado')
          });

        }
        catch (error)
        {
          commit('setEstatusSolicitud', 'error')
          throw error
        }

      },
      //////////////////////////////////////////////////////////////
      actualizarEstatusListadoSolicitudes({ commit })
      {
        try
        {

          const db = firebase.database()
          const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
          const uid = datosUsuario.uid

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

          referencia.orderByChild('clienteID')
          .equalTo(uid)
          .on('child_changed', function (snapshot)
          {
            const data = snapshot.val()

            for (let id in data['solicitud entrega'])
            {

              const estatusSolicitud = data['solicitud entrega'][id].estatusNuevoSolicitud.toLowerCase()

              data.timestampSolicitud = data['fechaSolicitud']

              if (estatusSolicitud === 'entregado' || estatusSolicitud === 'cancelado')
              {
                //Modificar solicitudes existentes
                commit('modificarSolicitudesFinalizadas', {
                  id: data['solicitud entrega'][id].idEntrega,
                  estatus: data['solicitud entrega'][id].estatusNuevoSolicitud.toLowerCase()
                })

                //Nuevas solicitudes
                const date = new Date(data['fechaSolicitud'])

                //data['solicitud entrega'][id].idEntrega = id
                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                // data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].estatusNuevoSolicitud = estatusSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setNuevasSolicitudesFinalizadas', data['solicitud entrega'][id])
              }

              if (estatusSolicitud === 'programado')
              {
                //Modificar solicitudes existentes
                commit('modificarSolicitudesProgramadas', {id: data['solicitud entrega'][id].idEntrega, estatus: data['solicitud entrega'][id].estatusEntrega.toLowerCase()})

                //Nuevas solicitudes
                const date = new Date(data['fechaSolicitud'])

                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setNuevasSolicitudesProgramadas', data['solicitud entrega'][id])
              }

              // if (estatusSolicitud === 'en curso' || estatusSolicitud ===  'conectando' || estatusSolicitud ===  'recolectando')
              if(estatusSolicitud === 'buscando'||estatusSolicitud === 'notificado'||estatusSolicitud === 'aceptado'||
                 estatusSolicitud === 'en establecimiento'||estatusSolicitud === 'recogido'||estatusSolicitud === 'en curso'
                 || estatusSolicitud === 'en punto de entrega')
              {
                //Modificar solicitudes existentes
                commit('modificarSolicitudesEnCurso', {
                  id: data['solicitud entrega'][id].idEntrega,
                  estatus: data['solicitud entrega'][id].estatusEntrega.toLowerCase(),
                  estatusNuevoSolicitud: data['solicitud entrega'][id].estatusNuevoSolicitud.toLowerCase(),
                  nombreRepartidor: data.nombreRepartidor,
                  fechaEnCurso: data.fechaEnCurso,
                  fechaAceptado: data.fechaAceptado,
                  fechaEnEstablecimiento: data.fechaEnEstablecimiento,
                })

                //Nuevas solicitudes
                const date = new Date(data['fechaSolicitud'])

                data['solicitud entrega'][id].idSolicitudCompleta = data.idSolicitud
                // data['solicitud entrega'][id].estatusSolicitud = estatusSolicitud
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                data['solicitud entrega'][id].idVinculada = data['idVinculada']
                data['solicitud entrega'][id].estatusEntrega = estatusSolicitud
                data['solicitud entrega'][id].estatusNuevoSolicitud = estatusSolicitud
                data['solicitud entrega'][id].nombreRepartidor = data.nombreRepartidor
                commit('setNuevasSolicitudesEnCurso', data['solicitud entrega'][id])
              }

            }

          })

        }
        catch (error)
        {
          alert(error)
        }

      },
      //////////////////////////////////////////////////////////////
      getSolicitudesRepartidores({ commit }, idVinculada)
      {
        try
        {
          const db = firebase.database()

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

          referencia.orderByChild('id vinculado')
          .equalTo(idVinculada)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            if(Object.entries(data).length === 0)
            {
              commit('setSolicitudesRepartidores', ['vacio'])
              return
            }

            if(data['Status'] === 'Aprobado')
            {
              console.log(data)
              data.idRepartidor = snapshot.key
              commit('setSolicitudesRepartidores', data)
            }

          })

        }
        catch(error)
        {
          alert(error)
        }
      },
      //////////////////////////////////////////////////////////////
      setOportunidadesViaje({ commit }, solicitud)
      {
        try
        {
          const db = firebase.database()
          const idRepartidor = solicitud.idRepartidor

          delete solicitud.idRepartidor

          /* //////  ////// */
          db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/' + idRepartidor + '/Oportunidades/')
          .update(solicitud)
        }
        catch (error)
        {
          commit('setEstatusSolicitud', 'error')
          throw error
        }
      },
      //////////////////////////////////////////////////////////////
      setNotificacionesRepartidores({ commit }, repartidores)
      {
        try
        {
          const db = firebase.database()
          const idSolicitud = repartidores.idSolicitud

          delete repartidores.idSolicitud

          /* //////  ////// */
          db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/' + idSolicitud + '/Repartidores notificados/')
          .update(repartidores)
          console.log(repartidores)
        }
        catch (error)
        {
          commit('setEstatusSolicitud', 'error')
          throw error
        }
      },
    },
}