
import firebase from 'firebase/app'
import 'firebase/database'

import { useImagen } from '../../../composables/setImagen'

export default {

  namespaced: true,
  state: 
  {
    usuario: null,
    estatusSolicitud: '',
    existeUsuario: false,
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations: 
  {
    setUsuario(state, usuario) 
    {
      state.usuario = usuario
    },
    setExisteUsuario(state, existeUsuario)
    {
      state.existeUsuario = existeUsuario
    },
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
  },
  actions: 
  {
    //////////////////////////////////////////////////////////////
    async getDatosAdministrador({ commit }, uid)
    {

      try
      {

        const db = firebase.database()
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/' + uid)            

        /* ////// Obtener informacion de cuenta ////// */    
        await referencia.once('value', function(snapshot) 
        {
          const data = snapshot.val()

          if(data !== '' && data !== null)
          {            

            const datosUsuario = {
              name: data.name,
              photoURL: data['customData'].photoURL,
              usuario: data['customData'].rol === 'soporte' ? data['customData'].rol : data['customData'].status,
              vinculado: data['customData'].idVinculada
            } 
  
            // console.log(data)
  
            /* ////// Guardar informacion en localStorage ////// */      
            // console.log(datosUsuario)
            window.localStorage.setItem('datosUsuario', JSON.stringify(datosUsuario))

            //Guardar informacion de soporte tecnico
            if(data['customData'].rol === 'soporte')
            {
              const referenciaSoporte = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico/' + uid)  
              
              referenciaSoporte.once('value', function(snapshot) 
              {
                const dataSoporte = snapshot.val()

                const datosSoporte = {
                  ...datosUsuario,
                  reestricciones: dataSoporte.reestricciones,
                  zonasCoberturas: dataSoporte.zonasCoberturas
                }

                window.localStorage.setItem('datosUsuario', JSON.stringify(datosSoporte))

              }) 

            }

            commit('setExisteUsuario', true)
          }
          else
          {
            commit('setExisteUsuario', false)
          }

        }) 

      }
      catch(error)
      {
        alert(error)
        commit('setExisteUsuario', false) 
      }
      
    },
    //////////////////////////////////////////////////////////////
    async getDetallesCuentaAdministrador({ commit })
    {

      try
      {

        commit('setEstatusSolicitud', 'cargando')
        
        const db = firebase.database()
        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = datosUsuario.uid
        
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/' + uid)            

        //let datosCuenta = {}

        /* ////// Guardar informacion de cuenta (members) ////// */      
        await referencia.once('value', function(snapshot) 
        {
          const data = snapshot.val()

          data.idZona = snapshot.key

          commit('setUsuario', data)

        }) 

      }
      catch(error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      } 
      finally 
      {
        commit('setEstatusSolicitud', 'finalizado')
      }     
    },
    //////////////////////////////////////////////////////////////
    actualizarDetallesCuentaAdministrador({ commit }, datosUsuario)
    {
      try 
      {
        const db = firebase.database()
        const uid = firebase.auth().currentUser.uid

        /* ////// Actualizar informacion en Firebase ////// */          
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/' + uid + '/customData')
        .update(datosUsuario)

        const alerta =
        {
          status: true,
          titulo: 'Información guardada',
          mensaje: 'Los detalles de la cuenta han sido actualizados.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      } 
      catch (error) 
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-center'
        }

        commit('setMensajeAlerta', alerta)         
      }
    },   
    //////////////////////////////////////////////////////////////
    async guardarFotoPerfilAdministrador({ commit }, imagen)
    {

      try 
      {

        const db = firebase.database()
        const uid = firebase.auth().currentUser.uid

        const { subirImagen } = useImagen()

        const imagenPerfil = {
          photoURL: await subirImagen(imagen),
        }

        /* ////// Guardar foto de perfil en members ////// */      
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/' + uid)
        .update(imagenPerfil)

        /* ////// Guardar foto de perfil en localStorage ////// */      
        const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'))
        const nuevosDatos = Object.assign(datosUsuario, imagenPerfil)
        localStorage.removeItem('datosUsuario')
        localStorage.setItem('datosUsuario', JSON.stringify(nuevosDatos))

        const alerta =
        {
          status: true,
          titulo: 'Imagen de perfil actualizada',
          mensaje: 'La imagen ha sido guardada exitosamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
        console.log(imagenPerfil)

      } 
      catch (error) 
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-center'
        }

        commit('setMensajeAlerta', alerta)     
      }
    },
    actualizarZonaSoporteTecnico({ commit }, informacionZona)
    {
      try 
      {
        const db = firebase.database()

        const datosUsuarioSoporte = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = datosUsuarioSoporte.uid

        /* ////// Actualizar usuario Soporte tecnico ////// */      
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico/' + uid)
        .update({ idVinculada: informacionZona.idVinculada, 
          idVinculadaName: informacionZona.nombreZona, 
          /*lat: informacionZona.zonaLat, 
          lng: informacionZona.zonaLng */
        })

        /* ////// Guardar nueva informacion de zoan en localStorage ////// */      
        const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'))
        const nuevosDatos = Object.assign(datosUsuario, { vinculado: informacionZona.idVinculada })

        localStorage.removeItem('datosUsuario')
        localStorage.setItem('datosUsuario', JSON.stringify(nuevosDatos))
      } 
      catch (error) 
      {
        commit('setUsuario', error) 
      }
    }
  },

}