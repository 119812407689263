
import firebase from 'firebase/app'
import 'firebase/database'

export default {

    namespaced: true,
    state:
    {
      datosFacturacion: {},
      estatusSolicitud: '',
      mensajeAlerta: {
        status: false,
        titulo: '',
        mensaje: '',
        variant: '',
        toaster: '',
      },
    },
    mutations:
    {
      setDatosFacturacion(state, datos)
      {
        state.datosFacturacion = datos
      },
      setEstatusSolicitud(state, status)
      {
        state.estatusSolicitud = status
      },
      setMensajeAlerta(state, alerta)
      {
        state.mensajeAlerta.status = alerta.status
        state.mensajeAlerta.titulo = alerta.titulo
        state.mensajeAlerta.mensaje = alerta.mensaje
        state.mensajeAlerta.variant = alerta.variant
        state.mensajeAlerta.toaster = alerta.toaster
      },
    },
    actions:
    {
      //////////////////////////////////////////////////////////////
      guardarDatosFacturacion({ commit }, datosFacturacion)
      {
        try
        {
          console.log('test aqui')
          const db = firebase.database()
          const uid = firebase.auth().currentUser.uid

          db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/facturacioon/' + uid)
          .set(datosFacturacion)

          let actualizarUsuario = ''

          /* ////// Guardar datos de facturacion de cuenta profesional ////// */
          if(datosFacturacion.cuenta === 'profesional')
          {
            actualizarUsuario = {
              cuenta: datosFacturacion.cuenta,
              nombreNegocio: datosFacturacion.nombreNegocio,
            }
          }

          /* ////// Guardar datos de facturacion de cuenta personal ////// */
          if(datosFacturacion.cuenta === 'personal')
          {
            actualizarUsuario = {
              cuenta: datosFacturacion.cuenta,
            }
          }

          db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid + '/customData')
          .update(actualizarUsuario)

          const alerta =
          {
            status: true,
            titulo: 'Informacion de facturación guardada',
            mensaje: 'La informacion de facturación ha sido guardada.',
            variant: 'success',
            toaster: 'b-toaster-top-right'
          }

          commit('setDatosFacturacion', datosFacturacion)
          commit('setMensajeAlerta', alerta)

        }
        catch (error)
        {
          const alerta =
          {
            status: false,
            titulo: 'Ha ocurrido un error',
            mensaje: 'ERROR: ' + error,
            variant: 'danger',
            toaster: 'b-toaster-top-center'
          }

          commit('setMensajeAlerta', alerta)
        }
      },
      //////////////////////////////////////////////////////////////
      async getDatosFacturacion({ commit })
      {
        try
        {

          commit('setEstatusSolicitud', 'cargando')

          const db = firebase.database()
          const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
          const uid = datosUsuario.uid

          const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/facturacioon/' + uid)

          /* ////// Obtener datos de facturacion ////// */
          await referencia.once('value', function(snapshot)
          {
            const data = snapshot.val()

            const datosCuentaUsuario = JSON.parse(localStorage.getItem('datosUsuario'))
            const datosTemporales = {
              nombre: datosCuentaUsuario.nombre,
              apellido: datosCuentaUsuario.apellido,
            }

            commit('setDatosFacturacion', data === null ? datosTemporales : data)

          })

        }
        catch(error)
        {
          alert(error)
          commit('setEstatusSolicitud', 'error')
        }
        finally {
          commit('setEstatusSolicitud', 'finalizado')
        }
      },

    },
}