
import firebase from 'firebase/app'
import 'firebase/database'

import { v4 as uuidv4 } from 'uuid'

export default {

  namespaced: true,
  state: 
  {
    estatusSolicitud: '',
    preguntasFrecuentes: [],
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations: 
  {
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setPreguntasFrecuentes(state, preguntas)
    {
      state.preguntasFrecuentes = preguntas
    },
    setNuevaPreguntaFrecuente(state, pregunta)
    {
      state.preguntasFrecuentes.push(pregunta)
    },
    modificarPreguntasFrecuentes(state, pregunta)
    {
      state.preguntasFrecuentes = state.preguntasFrecuentes.map(item => item.idPregunta === pregunta.idPregunta ? pregunta : item)
    },
    eliminarPreguntaFrecuente(state, idPregunta)
    {
      state.preguntasFrecuentes = state.preguntasFrecuentes.filter(item => item.idPregunta !== idPregunta)
    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
  },
  actions: 
  {
    //////////////////////////////////////////////////////////////
    getPreguntasFrecuentes({ commit })
    {

      try 
      {
        commit('setEstatusSolicitud', 'cargando')

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Preguntas frecuentes/')

        /* ////// Obtener listado de preguntas frecuentes ////// */    
        referencia.once('value', function(snapshot) 
        {
          const data = snapshot.val()
          const arrayDatos = []
  
          for (let id in data)
          {

            data[id].idPregunta = id
            arrayDatos.push(data[id])

          }

          commit('setPreguntasFrecuentes', arrayDatos)
          
        })
      } 
      catch (error) 
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }

    },
    //////////////////////////////////////////////////////////////
    guardarPreguntaFrecuente({ commit }, pregunta)
    {
      try
      {

        const db = firebase.database()
        const idPregunta = uuidv4()

        /* ////// Guardar pregunte frecuente ////// */    
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Preguntas frecuentes/' + idPregunta)
        .set(pregunta) 

        const alerta =
        {
          status: true,
          titulo: 'Pregunta guardada',
          mensaje: 'Se ha añadido una nueva pregunta a las preguntas frecuentes.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

        /* Añadir pregunta frecuente a State */
        const nuevaPregunta = Object.assign({ idPregunta: idPregunta }, pregunta)
        console.log(nuevaPregunta)
        commit('setNuevaPreguntaFrecuente', nuevaPregunta)  
      
      } catch (error) 
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    modificarPreguntaFrecuente({ commit }, pregunta)
    {

      try
      {
        const db = firebase.database()

        const preguntaModificada = 
        {
          ['Titulo']: pregunta.titulo,
          ['Descripcion']: pregunta.descripcion,                  
        }

        /* ////// Modificar pregunta frecuente ////// */    
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Preguntas frecuentes/' + pregunta.idPregunta)
        .set(preguntaModificada) 

        const alerta =
        {
          status: true,
          titulo: 'Pregunta modificada',
          mensaje: 'Se ha modificado la pregunta de preguntas frecuentes.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        /* Modificar pregunta frecuente en state */
        const preguntaModificadaState = Object.assign(preguntaModificada, { idPregunta: pregunta.idPregunta })
        
        commit('modificarPreguntasFrecuentes', preguntaModificadaState)  
        commit('setMensajeAlerta', alerta)

      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },  
    //////////////////////////////////////////////////////////////
    eliminarPreguntasFrecuentes({ commit }, idPregunta)
    {

      try
      { 

        const db = firebase.database()

        /* ////// Eliminar pregunta frecuente ////// */    
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Preguntas frecuentes/' + idPregunta).remove()

        const alerta =
        {
          status: true,
          titulo: 'Pregunta eliminada',
          mensaje: 'Se ha eliminado la pregunta de preguntas frecuentes.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }
  
        commit('eliminarPreguntaFrecuente', idPregunta) 
        commit('setMensajeAlerta', alerta)
      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }

    },  
  },

}