
import firebase from 'firebase/app'
import 'firebase/database'

import { getDistancia } from '../../../composables/distanciaOsrm'

export default {

  namespaced: true,
  state:
  {
    notificacionesUnoUno: [],
    repartidoresNotificaciones: [],
    detallesNotifiaciones: {},
    repartidoresConectados: [],
    mensajeAlertaNotificaciones: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations:
  {
    setNotificaciones(state, notificaciones)
    {
      state.notificacionesUnoUno.push(notificaciones)
    },
    setReiniciarNotificaciones(state)
    {
      state.notificacionesUnoUno = []
    },
    setrepartidoresNotificaciones(state, repartidores)
    {
      state.repartidoresNotificaciones.push(repartidores)
    },
    setReiniciarRepartidoresNotificaciones(state)
    {
      state.repartidoresNotificaciones = []
    },
    setDetallesNotificaciones(state, detallesNotifiaciones)
    {
      state.detallesNotifiaciones = detallesNotifiaciones
    },
    eliminarRepartidoresNotificados(state, idRepartidor)
    {
      state.repartidoresNotificaciones = state.repartidoresNotificaciones.filter((notificaciones) => notificaciones['Id repartidor'] !== idRepartidor)
    },
    modificarEstatusRepartidorNotificado(state, repartidor)
    {

      state.repartidoresNotificaciones.map(function (repartidores) {
        if(repartidores['Id repartidor'] === repartidor['Id repartidor'])
        {
          repartidores.repartidorNotificado = repartidor.repartidorNotificado
        }
      })

    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlertaNotificaciones.status = alerta.status
      state.mensajeAlertaNotificaciones.titulo = alerta.titulo
      state.mensajeAlertaNotificaciones.mensaje = alerta.mensaje
      state.mensajeAlertaNotificaciones.variant = alerta.variant
      state.mensajeAlertaNotificaciones.toaster = alerta.toaster
    },
  },
  actions:
  {
    //////////////////////////////////////////////////////////////
    getNotificacionesUnoUno({ commit })
    {

      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setReiniciarNotificaciones')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno')

        if(tipoUsuario === 'Admin')
        {
          ///
          referencia.once('value', function(snapshot)
          {
            const data = snapshot.val()

            for(const id in data)
            {

              const fechaRecoleccion = new Date(parseInt(data[id]['Hora']))

              data[id].idNotificacion = id
              data[id].idSolicitud = data[id]['Id solicitud']
              data[id].direccionRecoleccion = data[id]['Direccion-recoleccion']
              data[id].fechaRecoleccion = `${fechaRecoleccion.getDate()}/${fechaRecoleccion.getMonth()}/${fechaRecoleccion.getFullYear()} a las ${fechaRecoleccion.getHours()}:${fechaRecoleccion.getMinutes()}:${fechaRecoleccion.getSeconds()}`

              commit('setNotificaciones', data[id])
            }

          })
        }
        else
        {
          ///
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .once('value', function(snapshot)
          {
            const data = snapshot.val()

            for(const id in data)
            {

              const fechaRecoleccion = new Date(parseInt(data[id]['Hora']))

              data[id].idNotificacion = id
              data[id].idSolicitud = data[id]['Id solicitud']
              data[id].direccionRecoleccion = data[id]['Direccion-recoleccion']
              data[id].fechaRecoleccion = `${fechaRecoleccion.getDate()}/${fechaRecoleccion.getMonth()}/${fechaRecoleccion.getFullYear()} a las ${fechaRecoleccion.getHours()}:${fechaRecoleccion.getMinutes()}:${fechaRecoleccion.getSeconds()}`

              commit('setNotificaciones', data[id])
            }

          })
        }

      }
      catch(error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
    },
    //////////////////////////////////////////////////////////////
    getDetallesNotificaciones({ commit }, idSolicitud)
    {

      try
      {

        const db = firebase.database()

        commit('setReiniciarRepartidoresNotificaciones')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/')

        const detallesNotifiaciones = {}

        ///
        referencia.orderByChild('Distancia')
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()


          if(snapshot.key === idSolicitud)
          {
            const fechaRecoleccion = new Date(parseInt(data['Hora']))

            detallesNotifiaciones.timestamp = data['Hora']
            detallesNotifiaciones.longitudRecoleccion = data['longitud-recoleccion']
            detallesNotifiaciones.latitudeRecoleccion = data['latitud-recoleccion']
            detallesNotifiaciones.vehiculoRepartidor = data['vehiculoRepartidor']
            detallesNotifiaciones.idVinculada = data['idVinculada']
            detallesNotifiaciones.idSolicitudCompleta = idSolicitud
            detallesNotifiaciones.idSolicitud = data['Id solicitud']
            detallesNotifiaciones.direccionRecoleccion = data['Direccion-recoleccion']
            detallesNotifiaciones.fechaRecoleccion = `${fechaRecoleccion.getDate()}/${fechaRecoleccion.getMonth()}/${fechaRecoleccion.getFullYear()} a las ${fechaRecoleccion.getHours()}:${fechaRecoleccion.getMinutes()}:${fechaRecoleccion.getSeconds()}`

            commit('setDetallesNotificaciones', detallesNotifiaciones)
            console.log(data)

            for(const id in data['Repartidores'])
            {
              console.log(data['Repartidores'][id])
              // console.log(data[id])

              data['Repartidores'][id].repartidorNotificado = (data['Repartidores'][id]['Notificado'] === 'Si' ? 'Si' : 'No')
              data['Repartidores'][id].idRepartidor = data['Repartidores'][id]['Id repartidor']
              data['Repartidores'][id].nombreRepartidor = (data['Repartidores'][id]['Nombre'] === undefined ? '-' : data['Repartidores'][id]['Nombre'])
              data['Repartidores'][id].correoRepartidor = data['Repartidores'][id]['Correo']
              data['Repartidores'][id].distanciaRepartidor = data['Repartidores'][id]['Distancia'] + (data['Repartidores'][id]['Distancia'] !== undefined ? ' km' : '')
              data['Repartidores'][id].idSolicitud = idSolicitud

              commit('setrepartidoresNotificaciones', data['Repartidores'][id])
            }

          }

        })

      }
      catch(error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
    },
    //////////////////////////////////////////////////////////////
    notificarRepartidores({ commit }, informacionRepartidor)
    {
      try
      {
        const db = firebase.database()

        //let ejecucionNueva = true
        let verificarRepartidoresDisponibles = false

        const idSolicitud = informacionRepartidor.idSolicitud
        const idRepartidor = informacionRepartidor.repartidor
        const idVinculada = informacionRepartidor.idVinculada

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/')

        let repartidores = []

        ///
        referencia.orderByChild('Distancia')
        .on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          if(snapshot.key === idSolicitud)
          {

            for(const id in data['Repartidores'])
            {

              //Obtener a los repartidores
              if(data['Repartidores'][id]['Id repartidor'] !== idRepartidor && data['idVinculada'] === idVinculada)
              {
                data['Repartidores'][id].idSolicitud = idSolicitud
                repartidores.push(data['Repartidores'][id])

                console.log(data['Repartidores'][id])
              }

            }

          }

        })

        //Ordenar a los repartidores dependiendo su distancia
        repartidores = repartidores.sort((repartidorA, repartidorB) => parseFloat(repartidorA['Distancia']).toFixed(2) - parseFloat(repartidorB['Distancia']).toFixed(2))

        console.log(repartidores)
        console.log("######")
        console.log(verificarRepartidoresDisponibles)

        //Verificar que haya al menos un repartidor en notificaciones uno a uno
        if(repartidores.length >= 1)
        {

          for(const detalleRepartidor of repartidores)
          {

            //Obtener la informacion de repartidores
            const informacionRepartidor = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor`)

            ///
            informacionRepartidor
            .on('child_added', function (snapshotRepartidor)
            {
              const dataRepartidor = snapshotRepartidor.val()

              //Verificar que la informacion de un repartidor sea de un repartidor valido
              if(snapshotRepartidor.key === detalleRepartidor['Id repartidor'] && dataRepartidor['id vinculado'] === idVinculada)
              {
                console.log(dataRepartidor)

                //Verificar si el repartidor esta ocupado
                if(dataRepartidor['OCUPADO'] === 'NO' && dataRepartidor['EN LINEA'] === 'SI' && !verificarRepartidoresDisponibles)
                {
                  console.log("paso")
                  verificarRepartidoresDisponibles = true

                  detalleRepartidor.repartidorNotificado = 'Si'
                  console.log(detalleRepartidor)

                  //Modificar esatus de notificado a repartidores
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${idSolicitud}/Repartidores/${detalleRepartidor['Id repartidor']}`)
                  .update({['Notificado']: detalleRepartidor.repartidorNotificado})

                  //Informacion de oportunidad de entrega
                  const informacionOportunidadEntrega = {
                    ['OnTrack']: 'si',
                    ['id orden']: idSolicitud,
                    ['timestamp']: +new Date,
                    ['anterior']: 'null',
                    ['siguiente']: 'null',
                  }

                  //Guardar oportunidad de entrega
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${idSolicitud}`)
                  .set(informacionOportunidadEntrega)

                  //////
                  const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades`)

                  //Obtener las oportunidades de entrega
                  referenciaOportunidades.on('child_added', function (snapshotOportunidades)
                  {
                    const dataOportunidades = snapshotOportunidades.val()

                    //El primero en la cola
                    if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null' && snapshotOportunidades.key !== idSolicitud )//&& ejecucionNueva
                    {

                      //
                      db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                      .update({ ['anterior']: "null", ['siguiente']: idSolicitud })

                      //
                      db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${idSolicitud}`)
                      .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    }

                    //El ultimo en la cola
                    if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'] != idSolicitud )//&& ejecucionNueva
                    {

                      //
                      db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                      .update({['anterior']: dataOportunidades['anterior'], ['siguiente']: idSolicitud })

                      //
                      db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${idSolicitud}`)
                      .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    }

                  })

                  //ejecucionNueva = false

                  //Actualizar informacion de repartidor
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${detalleRepartidor['Id repartidor']}`)
                  .update({ ["EN LINEA"]: "SI", ["OCUPADO"]: "SI" })

                  commit('modificarEstatusRepartidorNotificado', detalleRepartidor)

                }

              }

            })

          }

        }

        //Eliminar repartidor ocupado en notificaciones uno a uno
        if(!verificarRepartidoresDisponibles && repartidores.length === 1)
        {

          for(const detalleRepartidor of repartidores)
          {
            //Eliminar oportunidades de entrega
            //db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${detalleRepartidor['Id repartidor']}/Oportunidades/${idSolicitud}`).remove()

            //Eliminar notificaciones uno a uno
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${idSolicitud}/Repartidores/${detalleRepartidor['Id repartidor']}`).remove()

            //Actualizar informacion de repartidor
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${detalleRepartidor['Id repartidor']}`)
            .update({ ["EN LINEA"]: "SI", ["OCUPADO"]: "NO" })
          }
        }

        /* -------------------------------------------------------- */
        //Notificar a todos los repartidores - Si no hay repartidores en notificaciones uno a uno
        if(!verificarRepartidoresDisponibles && repartidores.length <= 1)
        {

          const referenciaRepartidores = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

          ///
          referenciaRepartidores.orderByChild('Distancia')
          .on('child_added', function (snapshotSolicitudRepartidor)
          {
            const dataSolicitudRepartior = snapshotSolicitudRepartidor.val()

            //&& dataSolicitudRepartior['Vehiculo'] === solicitud.vehiculo
            if(dataSolicitudRepartior['id vinculado'] === idVinculada && dataSolicitudRepartior['Status'] === 'Aprobado')
            {
              const idRepartidorSolicitud = snapshotSolicitudRepartidor.key

              const informacionRepartidor = {
                ['Correo']: dataSolicitudRepartior['Correo'],
                //['Distancia']: distanciaRepartidor,
                ['Id repartidor']: idRepartidor,
                //['Latitud']: latRepartidor,
                //['Longitud']: lngRepartidor,
                ['Nombre']: dataSolicitudRepartior['Nombre'],
                ['Notificado']: 'Si',
                ['idVinculada']: dataSolicitudRepartior['id vinculado'],
                /*['latitud-recoleccion']: solicitud.latOrigen,
                ['longitud-recoleccion']: solicitud.lngOrigen,*/
                ['vehiculoRepartidor']: dataSolicitudRepartior['Vehiculo'],
              }

              //Guardar notificacion uno a uno
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${idSolicitud}/Repartidores/${idRepartidorSolicitud}`)
              .set(informacionRepartidor)

              ////////////
              const informacionOportunidadEntrega = {
                ['OnTrack']: 'si',
                ['id orden']: idSolicitud,
                ['timestamp']: +new Date,
                ['anterior']: 'null',
                ['siguiente']: 'null',
              }

              //Guardar oportunidad de entrega de repartidor notificado
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades/${idSolicitud}`)
              .set(informacionOportunidadEntrega)

              const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades`)

              //Obtener las oportunidades de entrega
              referenciaOportunidades.on('child_added', function (snapshotOportunidades)
              {
                const dataOportunidades = snapshotOportunidades.val()

                //El primero en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null' && snapshotOportunidades.key !== idSolicitud) //&& ejecucionNueva
                {

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({ ['anterior']: "null", ['siguiente']: idSolicitud })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades/${idSolicitud}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                }

                //El ultimo en la cola
                if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'] != idSolicitud) //&& ejecucionNueva
                {

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades/${dataOportunidades['id orden']}`)
                  .update({['anterior']: dataOportunidades['anterior'], ['siguiente']: idSolicitud })

                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidorSolicitud}/Oportunidades/${idSolicitud}`)
                  .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                }

              })

              //ejecucionNueva = false

              const commitRepartidores = {
                ...informacionRepartidor,
                correoRepartidor: dataSolicitudRepartior['Correo'],
                idRepartidor: idRepartidorSolicitud,
                repartidorNotificado: 'Si',
                idSolicitud: idSolicitud,
                nombreRepartidor: dataSolicitudRepartior['Nombre'],
              }

              commit('setrepartidoresNotificaciones', commitRepartidores)

            }

          })

        }

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    eliminarNotificaciones({ commit }, informacionRepartidor)
    {
      try
      {

        const db = firebase.database()

        const idSolicitud = informacionRepartidor.idSolicitud
        const idRepartidor = informacionRepartidor.repartidor

        console.log(idSolicitud + " - " + idRepartidor)

        //Eliminar oportunidades de entrega
        //db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${idSolicitud}`).remove()

        //Eliminar notificaciones uno a uno
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${idSolicitud}/Repartidores/${idRepartidor}`).remove()

        //Actualizar custom data de repartidor
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor/${idRepartidor}`)
        .update({ ["EN LINEA"]: "SI", ["OCUPADO"]: "NO" })

        commit('eliminarRepartidoresNotificados', idRepartidor)

        const alerta =
        {
          status: true,
          titulo: 'Notificación cancelada',
          mensaje: 'La notificación se ha cancelado satisfactoriamente.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    eliminarOportunidadesEntrega({ commit }, solicitud)
    {
      try
      {

        const db = firebase.database()
        let vecesEjecutado = 0

        //Obtener listado de repartidores
        const referenciaRepartidores = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores`)

        referenciaRepartidores.on('child_added', function (snapshot)
        {
          const data = snapshot.val()
          console.log(data)
          console.log(data['Id repartidor'] + " /// " + solicitud.id+ "///" +data['Notificado'])
          const notificado = data.Notificado
          const referenciaOportunidad = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades`)

          referenciaOportunidad.on('child_added', function (snapshotOportunidad)
          {
            const dataOportunidad = snapshotOportunidad.val()

            console.log(dataOportunidad)
            console.log("-----")
            console.log(notificado)

            /////
            //esta linea es la original
            // if(dataOportunidad['id orden'] === solicitud.id  )
            //en esta linea se cambia la logica para ver como funciona
            if(dataOportunidad['id orden'] === solicitud.id && notificado === 'Si')
            {
              // Es uno de enmedio
              if( dataOportunidad['anterior'] !== "null" && dataOportunidad['siguiente'] !== "null")
              {
                  // Actualizar al anterior de la cola y asignarle el siguiente de la oportunidad a eliminar
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['anterior']}`)
                  .update({ siguiente: dataOportunidad['siguiente'] })

                  // Actualizar al siguiente de la cola y asignarle el anterior de la oportunidad a eliminar
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['siguiente']}`)
                  .update({ anterior: dataOportunidad['anterior'] })
                  console.log('Es uno de enmedio',data['Id repartidor'],'y',dataOportunidad['id orden'])
              }

              // Es el primero pero no el último
              if( dataOportunidad['anterior'] === "null" && dataOportunidad['siguiente'] !== "null" )
              {
                  // Actualizar al siguiente en la cola el valor anterior null para que sea el primero de la cola
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['siguiente']}`)
                  .update({ anterior: dataOportunidad['anterior'] })
                  console.log('Es el primero pero no el último',data['Id repartidor'],'y',dataOportunidad['id orden'])
              }

              // Es el último pero no el primero
              if( dataOportunidad['siguiente'] === "null" && dataOportunidad['anterior'] !== "null" )
              {
                  // Actualizar al anterior en la cola el valor siguiente null para que sea el último de la cola
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['anterior']}`)
                  .update({ siguiente: dataOportunidad['siguiente'] })
                  console.log('Es el último pero no el primero',data['Id repartidor'],'y',dataOportunidad['id orden'])
              }

              //Eliminar oportunidades de entrega
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${solicitud.id}`).remove()
              console.log('se elimino',data['Id repartidor'],solicitud.id)
              vecesEjecutado ++
              console.log(vecesEjecutado)
            }

          })

          /*referenciaOportunidad.on('value', (snapshotOportunidad) =>
          {

            const dataOportunidad = snapshotOportunidad.val()

            console.log(dataOportunidad)

            // Es uno de enmedio
            if( dataOportunidad['anterior'] !== "null" && dataOportunidad['siguiente'] !== "null")
            {
                // Actualizar al anterior de la cola y asignarle el siguiente de la oportunidad a eliminar
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['anterior']}`)
                .update({ siguiente: dataOportunidad['siguiente'] })

                // Actualizar al siguiente de la cola y asignarle el anterior de la oportunidad a eliminar
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['siguiente']}`)
                .update({ anterior: dataOportunidad['anterior'] })
            }

            // Es el primero pero no el último
            if( dataOportunidad['anterior'] === "null" && dataOportunidad['siguiente'] !== "null" )
            {
                // Actualizar al siguiente en la cola el valor anterior null para que sea el primero de la cola
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['siguiente']}`)
                .update({ anterior: dataOportunidad['anterior'] })
            }

            // Es el último pero no el primero
            if( dataOportunidad['siguiente'] === "null" && dataOportunidad['anterior'] !== "null" )
            {
                // Actualizar al anterior en la cola el valor siguiente null para que sea el último de la cola
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${dataOportunidad['anterior']}`)
                .update({ siguiente: dataOportunidad['siguiente'] })
            }

            if( dataOportunidad['anterior'] === "null" && dataOportunidad['siguiente'] === "null" )
            {
              console.log('solo borrar')
            }

            //Eliminar oportunidades de entrega
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${data['Id repartidor']}/Oportunidades/${solicitud.id}`).remove()

          }, (errorObject) => {

            console.log('The read failed: ' + errorObject.name)

          })*/


        })

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    eliminarNotificacionesUnoUno({ commit }, solicitud)
    {
      try
      {

        const db = firebase.database()

        console.log("--")
        //Eliminar notificaciones uno a uno
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}`).remove()

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    cancelarSolicitud({ commit }, solicitud)
    {
      try
      {

       const db = firebase.database()

        /* ////// Actualizar estatus de la solicitud ////// */
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}`)
        .update({estatusSolicitud: solicitud.estatus, fechaConcluido: +new Date(), estatusNuevoSolicitud: 'Cancelado'})

        //Actualizar estatus en los puntos de entrega
        const referencia = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}/solicitud entrega`)

        referencia.on('child_added',function (snapshot)
        {

          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/${solicitud.id}/solicitud entrega/${snapshot.key}`)
          .update({ estatusEntrega: solicitud.estatus, estatusNuevoSolicitud: 'Cancelado' })

        })

        const alerta =
        {
          status: true,
          titulo: solicitud.estatus.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
          mensaje: solicitud.mensaje,
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    async eliminarPedidosTomadosRepartidor({ commit }, solicitud)
    {
      try
      {
        const db = firebase.database()

        //Obtener listado de repartidores
        const referenciaRepartidores = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores`)

        referenciaRepartidores.on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          console.log(data['Id repartidor'])

          //Eliminar pedido tomado repartidor
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Pedido tomado repartidor/${data['Id repartidor']}/Repartidor/${solicitud.id}`).remove()

          //Modificar el custom de data de repartidor
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_kVGLuWNp22skt75tXEC8ZE/members/${data['Id repartidor']}/customData`)
          .update({ ['Pedido en curso']: 'falso', ['Pedido en curso OnTrack']: 'falso' })

        })

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    async actualizarNotificacionesSolicitud({ commit }, solicitud)
    {
      try
      {

        const db = firebase.database()

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/geoFireGroups/Repartidores')

        const repartidoresConectados = []

        //Obtener el geofire de repartidores
        referencia.on('child_added', async function (snapshot)
        {

          const data = snapshot.val()

          //Verificar si los repartidores estan en la misma zona que el lider
          if(data['data']['id vinculado'] === solicitud.idVinculada)
          {

            if(data['data']['OCUPADO'] === 'NO' && data['data']['Vehiculo'] === solicitud.vehiculo)
            {
              console.log(data['data']['OCUPADO'] + " - " + data['data']['Vehiculo'])

              const latRepartidor = data['l'][0]
              const lngRepartidor = data['l'][1]

              console.log(snapshot.key)

              const { getDistanciaMapa } = getDistancia()

              const datosDistancia = {
                latOrigen: solicitud.latOrigen,
                lngOrigen: solicitud.lngOrigen,
                latDestino: data['l'][0],
                lngDestino: data['l'][1],
              }

              console.log(datosDistancia)
              //Obtener la distancia entre el repartidor y el punto de recoleccion
              const distanciaObtenida = await getDistanciaMapa(datosDistancia)

              //Distancia del repartidor
              const distanciaRepartidor = (parseFloat(distanciaObtenida.routes[0].distance) / 1000).toFixed(2) //(distanciaObtenida.distancia * 1.60934).toFixed(2)

              if(distanciaRepartidor <= parseFloat(solicitud.distanciaCobertura))
              {

                console.log(distanciaRepartidor)
                console.log("####")

                const informacionRepartidor = {
                  ['Correo']: data['data']['Correo'],
                  ['Distancia']: distanciaRepartidor,
                  ['Id repartidor']: snapshot.key,
                  ['Latitud']: latRepartidor,
                  ['Longitud']: lngRepartidor,
                  ['Nombre']: data['data']['Nombre'],
                  ['Notificado']: 'No',
                  ['idVinculada']: data['data']['id vinculado'],
                  ['latitud-recoleccion']: solicitud.latOrigen,
                  ['longitud-recoleccion']: solicitud.lngOrigen,
                  ['vehiculoRepartidor']: data['data']['Vehiculo'],
                }

                repartidoresConectados.push(informacionRepartidor)
              }

            }
          }

        })

        console.log(repartidoresConectados)
        console.log("hfhhf")

        setTimeout(() => {

          commit('setReiniciarRepartidoresNotificaciones')

          //Ordenar a los repartidores dependiendo su distancia
          const repartidoresOrdenados = repartidoresConectados.sort((repartidorA, repartidorB) => parseFloat(repartidorA['Distancia']) - parseFloat(repartidorB['Distancia']))

          console.log(repartidoresOrdenados)

          //Notificar a los repartidores conectados
          if(repartidoresOrdenados.length)
          {

            //
            repartidoresOrdenados[0]['Notificado'] = 'Si'

            //Guardar notificaciones uno a uno
            for(const repartidor of repartidoresOrdenados)
            {
              console.log("--")
              console.log(repartidor)
              console.log("--")

              //Guardar oportunidades de entrega a repartidor notificado
              if(repartidor['Notificado'] === 'Si')
              {

                const informacionOportunidadEntrega = {
                  ['OnTrack']: 'si',
                  ['id orden']: solicitud.id,
                  ['timestamp']: +new Date,
                  ['anterior']: "null",
                  ['siguiente']: "null",
                }

                //Guardar oportunidad de entrega de repartidor notificado
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                .set(informacionOportunidadEntrega)

                /*const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades`)

                //Obtener las oportunidades de entrega
                referenciaOportunidades.on('child_added', function (snapshotOportunidades)
                {
                  const dataOportunidades = snapshotOportunidades.val()

                  //Verificar solicitudes que no tengan solicitudes anteriores ni siguientes
                  if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null')
                  {

                    //Guardar oportunidad de entrega de repartidor notificado
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                    .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    //Actualizar siguiente en oportunidad de entrega
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                    .update({ ['siguiente']: solicitud.id })
                  }

                  //Verificar solicitudes que no tengan solicitudes siguientes pero si anteriores
                  if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'])
                  {

                    //Guardar oportunidad de entrega de repartidor notificado
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${solicitud.id}`)
                    .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    //Actualizar siguiente en oportunidad de entrega
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor['Id repartidor']}/Oportunidades/${dataOportunidades['id orden']}`)
                    .update({ ['siguiente']: solicitud.id })

                  }
                })     */

              }

              //Guardar notificacion uno a uno
              db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${repartidor['Id repartidor']}`)
              .set(repartidor)

              const detallesNotifiaciones = {
                //...repartidor,
                repartidorNotificado: (repartidor['Notificado'] === 'Si' ? 'Si' : 'No'),
                idRepartidor: repartidor['Id repartidor'],
                nombreRepartidor: repartidor['Nombre'],
                correoRepartidor: repartidor['Correo'],
                distanciaRepartidor: repartidor['Distancia'] + (repartidor['Distancia'] !== undefined ? ' km' : ''),
                idSolicitud: solicitud.id,
              }

              commit('setrepartidoresNotificaciones', detallesNotifiaciones)

            }

          }

          //Notificar a todos los repartidores
          if(!repartidoresOrdenados.length)
          {


            const referenciaSolicitudRepartidor = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Solicitud Repartidor')

            ///
            referenciaSolicitudRepartidor.on('child_added', function (snapshotSolicitudRepartidor)
            {
              const dataSolicitudRepartior = snapshotSolicitudRepartidor.val()

              if(dataSolicitudRepartior['id vinculado'] === solicitud.idVinculada && dataSolicitudRepartior['Vehiculo'] === solicitud.vehiculo)
              {

                const idRepartidor = snapshotSolicitudRepartidor.key

                const informacionRepartidor = {
                  ['Correo']: dataSolicitudRepartior['Correo'],
                  //['Distancia']: distanciaRepartidor,
                  ['Id repartidor']: idRepartidor,
                  //['Latitud']: latRepartidor,
                  //['Longitud']: lngRepartidor,
                  ['Nombre']: dataSolicitudRepartior['Nombre'],
                  ['Notificado']: 'Si',
                  ['idVinculada']: dataSolicitudRepartior['id vinculado'],
                  ['latitud-recoleccion']: solicitud.latOrigen,
                  ['longitud-recoleccion']: solicitud.lngOrigen,
                  ['vehiculoRepartidor']: dataSolicitudRepartior['Vehiculo'],
                }

                //Guardar notificacion uno a uno
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${idRepartidor}`)
                .set(informacionRepartidor)

                const detallesNotifiaciones = {
                  ...informacionRepartidor,
                  repartidorNotificado: 'Si',
                  idRepartidor: idRepartidor,
                  nombreRepartidor: dataSolicitudRepartior['Nombre'],
                  correoRepartidor: dataSolicitudRepartior['Correo'],
                  //distanciaRepartidor: repartidor['Distancia'] + (repartidor['Distancia'] !== undefined ? ' km' : ''),
                  idSolicitud: solicitud.id,
                }

                commit('setrepartidoresNotificaciones', detallesNotifiaciones)

                ////////////
                const informacionOportunidadEntrega = {
                  ['OnTrack']: 'si',
                  ['id orden']: solicitud.id,
                  ['timestamp']: +new Date,
                  /*['anterior']: "null",
                  ['siguiente']: "null",*/
                }

                //Guardar oportunidad de entrega de repartidor notificado
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
                .set(informacionOportunidadEntrega)

                const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades`)

                //Obtener las oportunidades de entrega
                referenciaOportunidades.on('child_added', function (snapshotOportunidades)
                {
                  const dataOportunidades = snapshotOportunidades.val()

                  //Verificar solicitudes que no tengan solicitudes anteriores ni siguientes
                  if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null')
                  {

                    //Guardar oportunidad de entrega de repartidor notificado
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
                    .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    //Actualizar siguiente en oportunidad de entrega
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${dataOportunidades['id orden']}`)
                    .update({ ['siguiente']: solicitud.id })
                  }

                  //Verificar solicitudes que no tengan solicitudes siguientes pero si anteriores
                  if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null')
                  {

                    //Guardar oportunidad de entrega de repartidor notificado
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${solicitud.id}`)
                    .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

                    //Actualizar siguiente en oportunidad de entrega
                    db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idRepartidor}/Oportunidades/${dataOportunidades['id orden']}`)
                    .update({ ['siguiente']: solicitud.id })

                  }
                })


              }

            })

          }

          const detallesSolicitud = {
            ['Direccion-recoleccion']: solicitud.direccionRecoleccion,
            ['Hora']: solicitud.timestamp,
            ['Id solicitud']: solicitud.id,
            ['idVinculada']: solicitud.idVinculada,
            ['latitud-recoleccion']: solicitud.latOrigen,
            ['longitud-recoleccion']: solicitud.lngOrigen,
            ['vehiculoRepartidor']: solicitud.vehiculo,
          }

          //Guardar detalles de solicitud en notificacion uno a uno
          db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}`)
          .update(detallesSolicitud)

          const informacionDetallesSolicitud = {
            ...detallesSolicitud,
            longitudRecoleccion: solicitud.latOrigen,
            latitudeRecoleccion: solicitud.lngOrigen,
            vehiculoRepartidor: solicitud.vehiculo,
            idVinculada: solicitud.idVinculada,
            idSolicitudCompleta: solicitud.id,
            idSolicitud: solicitud.id,
            timestamp: solicitud.timestamp,
            direccionRecoleccion: solicitud.direccionRecoleccion,
            fechaRecoleccion: solicitud.fechaRecoleccion,
          }

          commit('setDetallesNotificaciones', informacionDetallesSolicitud)

        }, 2000)

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    reasignarNotificaciones({ commit }, solicitud)
    {
      try
      {

        const db = firebase.database()

        //Obtener listado de repartidores
        const referenciaRepartidores = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores`)

        //
        commit('setReiniciarRepartidoresNotificaciones')

        referenciaRepartidores.on('child_added', function (snapshot)
        {
          const data = snapshot.val()

          console.log(data['Id repartidor'])

          //Repartidor seleccionado
          if(solicitud.repartidor === data['Id repartidor'])
          {
            //Modificar a notificado al repartidor seleccionado
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${data['Id repartidor']}`)
            .update({ ['Notificado']: 'Si' })

            //Guardar en State a los repartidores
            const commitRepartidores = {
              correoRepartidor: data['Correo'],
              idRepartidor: data['Id repartidor'],
              repartidorNotificado: 'Si',
              idSolicitud: solicitud.id,
              nombreRepartidor: data['Nombre'],
              ['Distancia']: data['Distancia'],
              distanciaRepartidor: data['Distancia'],
              ['idVinculada']: data['idVinculada'],
              ['vehiculoRepartidor']: data['vehiculoRepartidor'],
            }

            commit('setrepartidoresNotificaciones', commitRepartidores)

          }

          //Repartidores no seleccionados
          if(solicitud.repartidor !== data['Id repartidor'])
          {
            //Modificar a no notificado a los repartidos que no fueron seleccionados
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/NotificacionesUnoAUno/${solicitud.id}/Repartidores/${data['Id repartidor']}`)
            .update({ ['Notificado']: 'No' })

            //Guardar en State a los repartidores
            const commitRepartidores = {
              correoRepartidor: data['Correo'],
              idRepartidor: data['Id repartidor'],
              repartidorNotificado: 'No',
              idSolicitud: solicitud.id,
              nombreRepartidor: data['Nombre'],
              ['Distancia']: data['Distancia'],
              distanciaRepartidor: data['Distancia'],
              ['idVinculada']: data['idVinculada'],
              ['vehiculoRepartidor']: data['vehiculoRepartidor'],
            }

            commit('setrepartidoresNotificaciones', commitRepartidores)

          }

        })

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    setOportunidadesEntrega({ commit }, repartidor)
    {
      try
      {

        const db = firebase.database()

        ////////////
        const informacionOportunidadEntrega = {
          ['OnTrack']: 'si',
          ['id orden']: repartidor.idSolicitud,
          ['timestamp']: +new Date,
          ['anterior']: "null",
          ['siguiente']: "null",
        }

        //Guardar oportunidad de entrega de repartidor notificado
        db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades/${repartidor.idSolicitud}`)
        .set(informacionOportunidadEntrega)

        const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades`)

        //Obtener las oportunidades de entrega
        referenciaOportunidades.on('child_added', function (snapshotOportunidades)
        {
          const dataOportunidades = snapshotOportunidades.val()

          //El primero en la cola
          if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] === 'null' && snapshotOportunidades.key !== repartidor.idSolicitud) //&& ejecucionNueva
          {

            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades/${dataOportunidades['id orden']}`)
            .update({ ['anterior']: "null", ['siguiente']: repartidor.idSolicitud })

            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades/${repartidor.idSolicitud}`)
            .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

          }

          //El ultimo en la cola
          if(dataOportunidades['siguiente'] === 'null' && dataOportunidades['anterior'] !== 'null' && dataOportunidades['id orden'] != repartidor.idSolicitud) //&& ejecucionNueva
          {

            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades/${dataOportunidades['id orden']}`)
            .update({['anterior']: dataOportunidades['anterior'], ['siguiente']: repartidor.idSolicitud })

            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${repartidor.repartidor}/Oportunidades/${repartidor.idSolicitud}`)
            .update({ ['anterior']: dataOportunidades['id orden'], ['siguiente']: "null" })

          }
        })

      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },



    eliminarOportunidadesEntregaTemporal({ commit }, repartidor)
    {
      try
      {
        const db = firebase.database()

        const referenciaOportunidades = db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega`)

        //Obtener las oportunidades de entrega
        referenciaOportunidades.on('child_added', function (snapshotOportunidades)
        {
          const data = snapshotOportunidades.val()
          const idOportunidad = snapshotOportunidades.key

          if(idOportunidad !== repartidor.idRepartidor)
          {

            for(const oportunidad of Object.values(data['Oportunidades']))
            {
              if(oportunidad['id orden'] === repartidor.idSolicitud)
              {
                console.log('mmmms')
                console.log(oportunidad)

                // Es uno de enmedio
                if(oportunidad['anterior'] !== "null" && oportunidad['siguiente'] !== "null")
                {
                  // Actualizar al anterior de la cola y asignarle el siguiente de la oportunidad a eliminar
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idOportunidad}/Oportunidades/${oportunidad['anterior']}`)
                  .update({ siguiente: oportunidad['siguiente'] })

                  // Actualizar al siguiente de la cola y asignarle el anterior de la oportunidad a eliminar
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idOportunidad}/Oportunidades/${oportunidad['siguiente']}`)
                  .update({ anterior: oportunidad['anterior'] })
                }

                // Es el primero pero no el último
                if(oportunidad['anterior'] === "null" && oportunidad['siguiente'] !== "null" )
                {
                  // Actualizar al siguiente en la cola el valor anterior null para que sea el primero de la cola
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idOportunidad}/Oportunidades/${oportunidad['siguiente']}`)
                  .update({ anterior: oportunidad['anterior'] })
                }

                // Es el último pero no el primero
                if(oportunidad['siguiente'] === "null" && oportunidad['anterior'] !== "null" )
                {
                  // Actualizar al anterior en la cola el valor siguiente null para que sea el último de la cola
                  db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idOportunidad}/Oportunidades/${oportunidad['anterior']}`)
                  .update({ siguiente: oportunidad['siguiente'] })
                }

                //Eliminar oportunidades de entrega
                db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Oportunidad de entrega/${idOportunidad}/Oportunidades/${repartidor.idSolicitud}`).remove()

              }
            }

          }

        })
      }
      catch (error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    }

  },

}