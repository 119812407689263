
import firebase from 'firebase/app'
import 'firebase/database'

export default {

    namespaced: true,
    state:
    {
      estatusSolicitud: '',
      libretaDireccionesClientes: [],
      direccionesCliente: [],
      detalleDireccionCliente: {},
      detalleDireccionCliente1: {},
      mensajeAlerta: {
        status: false,
        titulo: '',
        mensaje: '',
        variant: '',
        toaster: '',
      },
    },
    mutations:
    {
      //pruebas
      setDetalleDireccionCliente1(state, direccion1)
      {
        state.detalleDireccionCliente1 = direccion1
        // console.log(state.detalleDireccionCliente)
      },
      //fin pruebas
      setReiniciarLibretaDireccionesClientes(state)
      {
        state.libretaDireccionesClientes = []
      },
      setLibretaDireccionesCliente(state, direccion)
      {
        state.libretaDireccionesClientes.push(direccion)
      },
      setDetalleDireccionCliente(state, direccion)
      {
        state.detalleDireccionCliente = direccion
        // console.log(state.detalleDireccionCliente)
      },

      setDireccionesCliente(state, direccion)
      {
        state.direccionesCliente.push(direccion)
      },
      setReiniciarDireccionesCliente(state)
      {
        state.direccionesCliente = []
      },
      setMensajeAlerta(state, alerta)
      {
        state.mensajeAlerta.status = alerta.status
        state.mensajeAlerta.titulo = alerta.titulo
        state.mensajeAlerta.mensaje = alerta.mensaje
        state.mensajeAlerta.variant = alerta.variant
        state.mensajeAlerta.toaster = alerta.toaster
      },
    },
    actions:
    {
        //////////////////////////////////////////////////////////////
        getLibretaDireccionesClientes({ commit })
        {
          try
          {

            const db = firebase.database()

            commit('setReiniciarLibretaDireccionesClientes')

            const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/clientesFrecuentes')

            //Obtener a los clientes frecuentes
            referencia.on('child_added', async function (snapshot)
            {

              const data = snapshot.val()

              data.idCliente = snapshot.key

              commit('setLibretaDireccionesCliente', data)

            })

          }
          catch (error)
          {
            throw new error
          }
        },
        //////////////////////////////////////////////////////////////
        getListadoDireccionesCliente({ commit }, idCliente)
        {
            try
            {

              const db = firebase.database()

              commit('setReiniciarDireccionesCliente')

              const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + idCliente + '/Mis direcciones')

              //Obtener a los clientes frecuentes
              referencia.on('child_added', async function (snapshot)
              {

                const data = snapshot.val()
                data.idDireccion = snapshot.key

                commit('setDireccionesCliente', data)

              })

            }
            catch (error)
            {
              throw new error
            }
        },
        //////////////////////////////////////////////////////////////
        getDireccionCiente({ commit }, cliente)
        {
            try
            {
                const db = firebase.database()
                const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + cliente.idCliente + '/Mis direcciones/' + cliente.idDireccion)
                //Obtener a los clientes frecuentes
                referencia.on('value', async function (snapshot)
                {
                  const data = snapshot.val()
                  data.idDireccion = snapshot.key
                  // console.log(data)
                  commit('setDetalleDireccionCliente', data)
                })
            }
            catch (error)
            {
              throw new error
            }
        },
        getDireccionCiente1({ commit }, cliente)
        {
            try
            {
              // console.log('......')
                const db = firebase.database()
                const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Direcciones-stuart/' + cliente.idCliente + '/Mis direcciones/' + cliente.idDireccion)
                //Obtener a los clientes frecuentes
                referencia.on('value', async function (snapshot)
                {
                  const data = snapshot.val()
                  data.idDireccion = snapshot.key
                  // console.log(data)
                  commit('setDetalleDireccionCliente1', data)
                })
            }
            catch (error)
            {
              throw new error
            }
        }
    },

}