
import firebase from 'firebase/app'
import 'firebase/database'

import { useImagen } from '../../../composables/setImagen'

export default {

    namespaced: true,
    state: 
    {
        usuario: {},
        existeUsuario: false,
        error: '',
        estatusSolicitud: '',
        mensajeAlerta: {
            status: false,
            titulo: '',
            mensaje: '',
            variant: '',
            toaster: '',
        },
    },
    mutations: 
    {
      setExisteUsuario(state, existeUsuario)
      {
        state.existeUsuario = existeUsuario
      },
      setUsuario(state, usuario) 
      {
        state.usuario = usuario
      },
      setError(state, error) 
      {
  
        const mostrarOpcion = {
          'auth/user-not-found': 'El correo electrónico ingresado no está asociado a ninguna cuenta.',
          'auth/invalid-password': 'La contraseña ingresada es incorrecta. Debe tener al menos 6 caracteres.',
          'auth/email-already-in-use': 'El correo electrónico ya está asociado a una cuenta.',
          'auth/invalid-email': 'El correo electrónico ingresado es incorrecto.',
          'auth/internal-error': 'El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud. Inténtelo de nuevo en otro momento.',
          'auth/wrong-password': 'La contraseña ingresada es incorrecta.',
        }
  
        const mensaje = mostrarOpcion[error] || '¡Ha ocurrido un error desconocido! Inténtelo de nuevo en otro momento.'
  
        state.error = (error != null ? mensaje : null)
      },
      setEstatusSolicitud(state, status)
      {
        state.estatusSolicitud = status
      },
      setMensajeAlerta(state, alerta)
      {
        state.mensajeAlerta.status = alerta.status
        state.mensajeAlerta.titulo = alerta.titulo
        state.mensajeAlerta.mensaje = alerta.mensaje
        state.mensajeAlerta.variant = alerta.variant
        state.mensajeAlerta.toaster = alerta.toaster
      },
    },
    actions:
    {
        //////////////////////////////////////////////////////////////    
        async crearNuevoUsuario({ commit }, usuario) 
        {
            try 
            {
                const db = firebase.database()
                const uidUsuario = usuario.uid

                //const respuesta = await firebase.auth().createUserWithEmailAndPassword(usuario.email, usuario.clave)

                /* ////// Guardar informacion usuario ////// */
                const formularioUsuario = {
                    name: usuario.name,
                    email: usuario.email,
                    phone: usuario.phone,
                    photoURL: usuario.photoURL,
                }

                await db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uidUsuario)
                .set(formularioUsuario)

                /* ////// Guardar informacion de cuenta profesional ////// */        
                if(usuario.cuenta === 'profesional')
                {
                    const formularioCuentaProfesional = {
                        apellido: usuario.apellido,
                        pais: usuario.pais,
                        negocio: usuario.negocio,
                        sector: usuario.sector,
                        enviosPorDia: usuario.enviosPorDia,
                        cuenta: usuario.cuenta,
                        telefonoTemporal: usuario.telefonoTemporal,
                        codigoPaisTelefono: usuario.codigoPaisTelefono,
                    }

                    await db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uidUsuario + '/customData')
                    .set(formularioCuentaProfesional)
                }

                /* ////// Guardar informacion de cuenta personal ////// */        
                if(usuario.cuenta === 'personal')
                {
                    const formularioCuentaPersonal = {
                        apellido: usuario.apellido,
                        pais: usuario.pais,
                        cuenta: usuario.cuenta,
                        telefonoTemporal: usuario.telefonoTemporal,
                        codigoPaisTelefono: usuario.codigoPaisTelefono,
                    }

                    await db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uidUsuario + '/customData')
                    .set(formularioCuentaPersonal)
                }

                console.log(usuario)

                /* ////// Guardar informacion en localStorage ////// */        
                const datosUsuario = {
                    name: usuario.name,
                    apellido: usuario.apellido,
                    photoURL: '',
                    negocio: usuario.cuenta !== 'personal' ? usuario.negocio : '', 
                    tipoCuenta: usuario.cuenta
                }        

                window.localStorage.setItem('datosUsuario', JSON.stringify(datosUsuario))
                
                //
                commit('setError', null)  

            } catch (error) 
            {
                console.log(error)
                commit('setError', error)          
            }
        },
        //////////////////////////////////////////////////////////////   
        async getDatosUsuario({ commit }, uid)
        {

            try
            {

                const db = firebase.database()
                const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid)            

                /* ////// Obtener informacion de cuenta ////// */    
                await referencia.once('value', function(snapshot) 
                {
                const data = snapshot.val()

                if(data !== '' && data !== null)
                {
                    console.log( data)
                    const datosUsuario = {
                        name: data.name,
                        apellido: data['customData'].apellido,
                        photoURL: data.photoURL,
                        negocio: data['customData'].cuenta !== 'personal' ? data['customData'].negocio : '',
                        tipoCuenta: data['customData'].cuenta,
                        codigo: data.code ?? ''
                    } 
        
                    /* ////// Guardar informacion en localStorage ////// */      
                    // console.log(datosUsuario)
                    window.localStorage.setItem('datosUsuario', JSON.stringify(datosUsuario))

                    commit('setExisteUsuario', true)
                }
                else
                {
                    commit('setExisteUsuario', false)
                }

                }) 

            }
            catch(error)
            {
                alert(error)
                commit('setExisteUsuario', false) 
            }

        },
        //////////////////////////////////////////////////////////////
        async guardarFotoPerfil({ commit }, imagen)
        {

            try 
            {

                const db = firebase.database()
                const uid = firebase.auth().currentUser.uid

                const { subirImagen } = useImagen()

                const imagenPerfil = {
                photoURL: await subirImagen(imagen),
                }

                /* ////// Guardar foto de perfil en members ////// */      
                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid)
                .update(imagenPerfil)

                /* ////// Guardar foto de perfil en localStorage ////// */      
                const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'))
                const nuevosDatos = Object.assign(datosUsuario, imagenPerfil)
                localStorage.removeItem('datosUsuario')
                localStorage.setItem('datosUsuario', JSON.stringify(nuevosDatos))

                const alerta =
                {
                    status: true,
                    titulo: 'Imagen de perfil actualizada',
                    mensaje: 'La imagen ha sido guardada exitosamente.',
                    variant: 'success',
                    toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)
                console.log(imagenPerfil)

            } 
            catch (error) 
            {
                const alerta =
                {
                    status: false,
                    titulo: 'Ha ocurrido un error',
                    mensaje: 'ERROR: ' + error,
                    variant: 'danger',
                    toaster: 'b-toaster-top-center'
                }

                commit('setMensajeAlerta', alerta)     
            }
        },
        //////////////////////////////////////////////////////////////
        async getDetallesCuenta({ commit })
        {

            try
            {

                commit('setEstatusSolicitud', 'cargando')

                const db = firebase.database()
                const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
                const uid = datosUsuario.uid
                
                const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid)            
                const referenciaCustomData = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid + '/customData')

                let datosCuenta = {}
                let datosCustomData = {}

                /* ////// Guardar informacion de cuenta (members) ////// */      
                await referencia.once('value', function(snapshot) 
                {
                    const data = snapshot.val()

                    datosCuenta = {
                        name: data.name,
                        phone: data.phone,
                        email: data.email,
                        
                    } 

                }) 

                /* ////// Guardar informacion de cuenta (customData) ////// */      
                await referenciaCustomData.once('value', function(datos) 
                {
                
                    const data = datos.val()

                    datosCustomData = {
                        cuenta: data.cuenta, 
                        apellido: data.apellido,
                        nombreNegocio: data.negocio,
                        codigoPaisTelefono: data.codigoPaisTelefono,
                        telefonoTemporal: data.telefonoTemporal,
                        
                    }
                })

                console.log({ ...datosCuenta, ...datosCustomData })
                commit('setUsuario', { ...datosCuenta, ...datosCustomData })

            }
            catch(error)
            {
                alert(error) 
                commit('setEstatusSolicitud', 'error')
            } 
            finally {
                commit('setEstatusSolicitud', 'finalizado')
            }     
        },
        //////////////////////////////////////////////////////////////
        actualizarDetallesCuenta({ commit }, detallesCuenta)
        {
            try 
            {
                const db = firebase.database()
                const uid = firebase.auth().currentUser.uid
                
                const nuevaInformacionCuenta = {
                    nombre: detallesCuenta.name,
                    apellido: detallesCuenta.phone
                }

                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid)
                .update({ name: detallesCuenta.name, phone: detallesCuenta.phone })

                delete detallesCuenta.name
                delete detallesCuenta.phone

                /* ////// Actualizar informacion en Firebase ////// */          
                db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_rZFeuTrax5DSobxXa3F448/members/' + uid + '/customData')
                .update(detallesCuenta)

                /* ////// Actualizar informacion en localStorage ////// */    
                const datosUsuario = JSON.parse(localStorage.getItem('datosUsuario'))
                delete datosUsuario.nombre
                delete datosUsuario.apellido

                const nuevosDatos = Object.assign(datosUsuario, nuevaInformacionCuenta)
                localStorage.removeItem('datosUsuario')
                localStorage.setItem('datosUsuario', JSON.stringify(nuevosDatos))

                const alerta =
                {
                    status: true,
                    titulo: 'Información guardada',
                    mensaje: 'Los detalles de la cuenta han sido actualizados.',
                    variant: 'success',
                    toaster: 'b-toaster-top-right'
                }

                commit('setMensajeAlerta', alerta)

            } 
            catch (error) 
            {
                const alerta =
                {
                status: false,
                titulo: 'Ha ocurrido un error',
                mensaje: 'ERROR: ' + error,
                variant: 'danger',
                toaster: 'b-toaster-top-center'
                }

                commit('setMensajeAlerta', alerta)         
            }
        },
    },
}