
import firebase from 'firebase/app'
import 'firebase/database'

export default {

  namespaced: true,
  state: 
  {
    estatusSolicitud: '',
    existeUsuario: false,
    estatusSolicitudPrecioLider: '',
    estatusSolicitudTiempoEstimado: '',
    detallesCobertura: '',
    detallesTiempoEstimado: '',
    detallesPrecioLider: '',
    detalleAreaCobertura: {},
    listadoCobertura: [],
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations: 
  {
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setReiniciarListadoCobertura(state)
    {
      state.listadoCobertura = []
    },
    setListadoCobertura(state, coberturas)
    {
      state.listadoCobertura.push(coberturas)
    },
    setDetalleAreasCobertura(state, area)
    {
      state.detalleAreaCobertura = area
    },
    updatedAreaCobertura(state, area)
    {
      if(state.detalleAreaCobertura.idZona === area.idZona)
      {
        state.detalleAreaCobertura = area
      }
    },
    setEstatusSolicitudPrecioLider(state, status)
    {
      state.estatusSolicitudPrecioLider = status
    },
    setEstatusSolicitudTiempoEstimado(state, status)
    {
      state.estatusSolicitudTiempoEstimado = status
    },
    setCobertura(state, cobertura)
    {
      state.detallesCobertura = cobertura
    },
    setTiemposEstimados(state, tiemposEstimados)
    {
      state.detallesTiempoEstimado = tiemposEstimados
    },
    setPreciosLider(state, precios)
    {
      state.detallesPrecioLider = precios
    },
    setMensajeAlerta(state, alerta)
    {
      state.mensajeAlerta.status = alerta.status
      state.mensajeAlerta.titulo = alerta.titulo
      state.mensajeAlerta.mensaje = alerta.mensaje
      state.mensajeAlerta.variant = alerta.variant
      state.mensajeAlerta.toaster = alerta.toaster
    },
  },
  actions: 
  {
    //////////////////////////////////////////////////////////////
    getCobertura({ commit }, uid)
    {

      try
      {

        commit('setEstatusSolicitud', 'cargando')

        const db = firebase.database() 
        
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en radios/' + uid)

        const datosCobertura = {}

        //.equalTo(uid)
        referencia.on('child_added', function (snapshot) 
        {
          const data = snapshot.val()

          datosCobertura[snapshot.key] = data

          commit('setCobertura', datosCobertura)

        })

      } 
      catch (error) 
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }  
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }
    },
    //////////////////////////////////////////////////////////////
    getTiemposEstimadoAdministrador({ commit }, uid)
    {

      try
      {

        commit('setEstatusSolicitudTiempoEstimado', 'cargando')

        const db = firebase.database() 
        
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Tiendas exxpres/' + uid)

        const datosTiempoEstimado = {}

        //.equalTo(uid)
        referencia.on('child_added', function (snapshot) 
        {
          const data = snapshot.val()

          datosTiempoEstimado[snapshot.key] = data

          commit('setTiemposEstimados', datosTiempoEstimado)

        })

      } 
      catch (error) 
      {
        commit('setEstatusSolicitudTiempoEstimado', 'error')
        throw error
      }  
      finally
      {
        commit('setEstatusSolicitudTiempoEstimado', 'finalizado')
      }
    },
    //////////////////////////////////////////////////////////////
    getPreciosLider({ commit }, uid)
    {

      try
      {

        commit('setEstatusSolicitudPrecioLider', 'cargando')

        const db = firebase.database() 
        
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Precios-stuart/' + uid)

        const datosPrecios = {}

        //.equalTo(uid)
        referencia.on('child_added', function (snapshot) 
        {
          const data = snapshot.val()

          datosPrecios[snapshot.key] = data

          commit('setPreciosLider', datosPrecios)

        })

      } 
      catch (error) 
      {
        commit('setEstatusSolicitudPrecioLider', 'error')
        throw error
      }  
      finally
      {
        commit('setEstatusSolicitudPrecioLider', 'finalizado')
      }
    },
    //////////////////////////////////////////////////////////////
    modificarTiemposEstimadoLider({ commit }, precios)
    {
      try
      {
        const db = firebase.database()
        const uid = precios.uid

        delete precios.uid

        /* ////// Modificar tiempos estimados en Firebase ////// */          
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Tiendas exxpres/' + uid)
        .update(precios)  

        const alerta =
        {
          status: true,
          titulo: 'Tiempos estimados de OnTrack',
          mensaje: 'Los cambios han sido guardados.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    modificarCoberturaLider({ commit }, cobertura)
    {
      try
      {

        const db = firebase.database()
        const uid = cobertura.uid

        delete cobertura.uid

        /* ////// Modificar cobertura en Firebase ////// */          
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en radios/' + uid)
        .update(cobertura)  

        const alerta =
        {
          status: true,
          titulo: 'Cobertura OnTrack',
          mensaje: 'Los cambios han sido guardados.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    //////////////////////////////////////////////////////////////
    modificarPreciosLider({ commit }, precios)
    {
      try
      {

        const db = firebase.database()
        const uid = precios.uid
        const volumenPaquete = precios.volumen

        delete precios.volumen
        delete precios.uid

        /* ////// Modificar precios en Firebase ////// */          
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Precios-stuart/' + uid + '/Precios/' + volumenPaquete)
        .update(precios)  

        const alerta =
        {
          status: true,
          titulo: 'Tarifa OnTrack',
          mensaje: 'Los cambios han sido guardados.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)

      }
      catch(error)
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)
      }
    },
    getDetallesAreaCobertura({ commit }, idZona)
    {

        const db = firebase.database() 
        
        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en poligonos/' + idZona)

        const datosPrecios = {}

        referencia.on('child_added', function (snapshot) 
        {
          const data = snapshot.val()


          datosPrecios[snapshot.key] = data
          datosPrecios.idZona = idZona

          console.log(datosPrecios)
          commit('setDetalleAreasCobertura', datosPrecios)

        })

    },
    getAreasCoberturas({ commit })
    {

      const db = firebase.database() 

      commit('setReiniciarListadoCobertura')
      
      const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en poligonos')

      const areasCobertura = []

      referencia.on('child_added', function (snapshot) 
      {
        const data = snapshot.val()

        data.idZona = snapshot.key
        areasCobertura.push(data)
        commit('setListadoCobertura', data)
      })

      console.log(areasCobertura)
    },
    updatedAreaCobertura({ commit }, cobertura)
    {
      try 
      {

        const db = firebase.database()
        const idZona = cobertura.idZona

        delete cobertura.idZona

        /* ////// Modificar areas de cobertura ////// */          
        db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/Cobertura en poligonos/' + idZona)
        .update(cobertura)  

        commit('updatedAreaCobertura', { ...cobertura, idZona: idZona })

        const alerta =
        {
          status: true,
          titulo: 'Área de cobertura actualizada',
          mensaje: 'Los cambios han sido guardados.',
          variant: 'success',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)        
      } 
      catch (error) 
      {
        const alerta =
        {
          status: false,
          titulo: 'Ha ocurrido un error',
          mensaje: 'ERROR: ' + error,
          variant: 'danger',
          toaster: 'b-toaster-top-right'
        }

        commit('setMensajeAlerta', alerta)        
      }
    }
  },

}