
import firebase from 'firebase/app'
import 'firebase/database'

export default {

  namespaced: true,
  state:
  {
    estatusSolicitud: '',
    solicitudesReporte: [],
    mensajeAlerta: {
      status: false,
      titulo: '',
      mensaje: '',
      variant: '',
      toaster: '',
    },
  },
  mutations:
  {
    setEstatusSolicitud(state, status)
    {
      state.estatusSolicitud = status
    },
    setReiniciarSolicitudesReporte(state)
    {
      state.solicitudesReporte = []
    },
    setSolicitudesReporte(state, solicitud)
    {
      state.solicitudesReporte.push(solicitud)
    },
  },
  actions:
  {
    //////////////////////////////////////////////////////////////
    getSolicitudesReporteAdministrador({ commit })
    {
      try
      {

        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudesReporte')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {
          referencia.on('child_added', function (snapshot)
          {
            const data = snapshot.val()
            console.log(data)
            if (data.estatusSolicitud.toLowerCase() === 'finalizado')
            {
              /*for (let id in data['solicitud entrega'])
              {*/
                //const date = new Date(data['fechaSolicitud'])

                //data['solicitud entrega'][id].idEntrega = id
                /*data['solicitud entrega'][id].estatusSolicitud = data.estatusSolicitud.toLowerCase()
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                commit('setSolicitudesReporte', data['solicitud entrega'][id])*/
                console.log(data)

              //}
            }

            commit('setSolicitudesReporte', data)

          })
        }
        else
        {
          referencia.orderByChild('idVinculada')
          .equalTo(uid)
          .on('child_added', function (snapshot)
          {
            const data = snapshot.val()

            if (data.estatusSolicitud.toLowerCase() === 'finalizado')
            {
              /*for (let id in data['solicitud entrega'])
              {*/
                //const date = new Date(data['fechaSolicitud'])

                //data['solicitud entrega'][id].idEntrega = id
                /*data['solicitud entrega'][id].estatusSolicitud = data.estatusSolicitud.toLowerCase()
                data['solicitud entrega'][id].fechaSolicitud = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "   " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                data['solicitud entrega'][id].totalSolicitud = data['totalSolicitud']
                commit('setSolicitudesReporte', data['solicitud entrega'][id])*/
                console.log(data)

              //}
            }

            commit('setSolicitudesReporte', data)

          })
        }

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw error
      }
      finally
      {
        commit('setEstatusSolicitud', 'finalizado')
      }
    },

    //////////////////////////////////////////////////////////////
    getSolicitudesReporteFiltradoAdministrador({ commit }, informacionSolicitud)
    {
      try
      {
        const db = firebase.database()

        const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
        const tipoUsuario = usuarioAutentificado.usuario

        const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
        const uid = tipoUsuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudesReporte')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        if(tipoUsuario === 'Admin')
        {
          referencia.orderByChild('fechaSolicitud')
          //.equalTo(informacionSolicitud.idVinculada)
          .startAt(informacionSolicitud.fechaInicio)
          .endAt(informacionSolicitud.fechaFinal)
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            commit('setSolicitudesReporte', data)

          })
        }

        if(tipoUsuario !== 'Admin')
        {
          referencia.orderByChild('idVinculada_Timestamp')
          //.equalTo(informacionSolicitud.idVinculada)
          .startAt(uid + '_' + informacionSolicitud.fechaInicio)
          .endAt(uid + '_' + informacionSolicitud.fechaFinal)
          .on('child_added', function (snapshot)
          {

            const data = snapshot.val()

            commit('setSolicitudesReporte', data)

          })
        }

        commit('setEstatusSolicitud', 'finalizado')

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },

    //////////////////////////////////////////////////////////////
    getSolicitudesFacturaAdministrador({ commit }, informacionSolicitud)
    {
      try
      {
        const db = firebase.database()

        commit('setEstatusSolicitud', 'cargando')
        commit('setReiniciarSolicitudesReporte')

        const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SolicitudesOnTrack/')

        referencia.orderByChild('clienteID_Timestamp')
        .startAt(informacionSolicitud.fechaInicio)
        .endAt(informacionSolicitud.fechaFinal)
        .on('child_added', function (snapshot)
        {

          const data = snapshot.val()
          commit('setSolicitudesReporte', data)

        })

        commit('setEstatusSolicitud', 'finalizado')

      }
      catch (error)
      {
        commit('setEstatusSolicitud', 'error')
        throw new error
      }
    },

  },
}