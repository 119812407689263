
import axios from 'axios'

export const getDistancia = () =>
{

    const getDistanciaMapa = async(ubicacion) =>
    {
        let distanciaTotal = {}
        // console.log(ubicacion)

        const configuracion = {
            method: 'GET',
            url: `http://router.project-osrm.org/route/v1/driving/${ ubicacion.lngOrigen },${ ubicacion.latOrigen };${ ubicacion.lngDestino },${ ubicacion.latDestino }?overview=false`,
        }

        await axios(configuracion)
            .then(res => distanciaTotal = res.data)
            .catch(err => console.log(err))

        return distanciaTotal

    }

    const getRutaOptima = async(ubicaciones) =>
    {
        let distanciaTotal = {}
        // console.log(ubicaciones)

        const configuracion = {
            method: 'GET',
            url: `http://router.project-osrm.org/trip/v1/driving/${ ubicaciones }source=first&destination=last&roundtrip=false&geometries=geojson`,
        }

        await axios(configuracion)
            .then(res => distanciaTotal = res.data)
            .catch(err => console.log(err))

        return distanciaTotal
    }

    return { getDistanciaMapa, getRutaOptima }

}