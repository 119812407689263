
import firebase from 'firebase/app'
import 'firebase/database'

export default {

    namespaced: true,
    state: 
    {
      estatusSolicitud: '',
      miembrosSoporteTecnico: [],
      detallesSoporteTecnico: {},
      mensajeAlerta: {
        status: false,
        titulo: '',
        mensaje: '',
        variant: '',
        toaster: '',
      },
      mensajeAlertaSoporteTecnico: {
        status: false,
        titulo: '',
        mensaje: '',
        variant: '',
        toaster: '',
      },
    },
    mutations: 
    {
      setDetallesSoporteTecnico(state, soporteTecnico)
      {
        state.detallesSoporteTecnico = soporteTecnico
      },
      setReiniciarSoporteTecnico(state)
      {
        state.miembrosSoporteTecnico = []
      },
      setMiembroSoporteTecnico(state, miembro)
      {
        state.miembrosSoporteTecnico.push(miembro)
      },
      setMensajeAlerta(state, alerta)
      {
        state.mensajeAlerta.status = alerta.status
        state.mensajeAlerta.titulo = alerta.titulo
        state.mensajeAlerta.mensaje = alerta.mensaje
        state.mensajeAlerta.variant = alerta.variant
        state.mensajeAlerta.toaster = alerta.toaster
      },
      setMensajeAlertaSoporteTecnico(state, alerta)
      {
        state.mensajeAlertaSoporteTecnico.status = alerta.status
        state.mensajeAlertaSoporteTecnico.titulo = alerta.titulo
        state.mensajeAlertaSoporteTecnico.mensaje = alerta.mensaje
        state.mensajeAlertaSoporteTecnico.variant = alerta.variant
        state.mensajeAlertaSoporteTecnico.toaster = alerta.toaster
      }
    },
    actions: 
    {
        //////////////////////////////////////////////////////////////
        async guardarNuevoMiembroSoporteTecnico({ commit }, miembro)
        {
            try 
            {
                console.log(miembro)
                console.log(firebase)

                const nuevoUsuario = await fetch(`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${ process.env.VUE_APP_APIKEY }`,{
                        method: 'POST',
                        body: JSON.stringify({
                        email: miembro['email'], 
                        password: miembro['clave'],
                        returnSecureToken: true
                    })
                })
          
                // Respuesta de la peticion
                const usuarioCreado = await nuevoUsuario.json()
                console.log(usuarioCreado)

                //Mostrar error
                if (usuarioCreado.error) 
                {

                    const mostrarError = {
                        'EMAIL_EXISTS': 'El correo electrónico ya está asociado a una cuenta.',
                        'OPERATION_NOT_ALLOWED': 'El acceso con contraseña está inhabilitado para este proyecto.',
                        'TOO_MANY_ATTEMPTS_TRY_LATER': 'Bloqueamos todas las solicitudes de este dispositivo debido a actividades inusuales.',
                    }
                
                    const mensaje = mostrarError[usuarioCreado.error.message] || '¡Ha ocurrido un error desconocido! Inténtelo de nuevo en otro momento.'
                
                    const alerta =
                    {
                        status: false,
                        titulo: 'Ha ocurrido un error al',
                        mensaje: 'ERROR: ' + mensaje,
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    }
            
                    commit('setMensajeAlerta', alerta)  
                    return
                }

                ///////////
                const db = firebase.database()

                const informacionMembers = {
                    ['customData']: {
                        ['idVinculada']: miembro['idVinculada'],
                        ['idVinculadaName']: miembro['idVinculadaName'],
                        ['status']: miembro['status'],
                        ['rol']: miembro['rol'],
                    },
                    ['name']: miembro['name'],
                    ['phone']: miembro['phone'],
                    ['email']: miembro['email'],
                }

                await db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/' + usuarioCreado.localId)
                .set(informacionMembers)

                const informacionSoporteTecnico = {
                    ['createdAt']: miembro['createdAt'],
                    ['email']: miembro['email'],
                    ['idVinculada']: miembro['idVinculada'],
                    ['idVinculadaName']: miembro['idVinculadaName'],
                    ['lastName']: miembro['lastName'],
                    ['name']: miembro['name'],
                    ['phone']: miembro['phone'],
                    ['status']: miembro['status'],
                    ['userWhoWriteThisRegister']: miembro['userWhoWriteThisRegister'],
                    ['zonasCoberturas']: miembro['zonasCoberturas'],
                    ['reestricciones']: miembro['reestricciones'],
                }

                await db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico/' + usuarioCreado.localId)
                .set(informacionSoporteTecnico)
         
                const alerta =
                {
                  status: true,
                  titulo: 'Miembro agregado',
                  mensaje: 'Se ha agregado a ' + miembro['name'] + ' al equipo de soporte técnico.',
                  variant: 'success',
                  toaster: 'b-toaster-top-right'
                }
        
                commit('setMensajeAlerta', alerta)                
            } 
            catch (error) 
            {
                const alerta =
                {
                  status: false,
                  titulo: 'Ha ocurrido un error',
                  mensaje: 'ERROR: ' + error,
                  variant: 'danger',
                  toaster: 'b-toaster-top-right'
                }
        
                commit('setMensajeAlerta', alerta)                
            }
        },
        getMiembrosSoporteTecnico({ commit })
        {
          try 
          {

            const db = firebase.database() 

            const usuarioAutentificado = JSON.parse(localStorage.getItem('datosUsuario'))
            const administrador = ( usuarioAutentificado.usuario === 'Admin' ? true : false )

            const datosUsuario = JSON.parse(sessionStorage.getItem('authUser'))
            const uid = usuarioAutentificado.usuario === 'soporte' ? usuarioAutentificado.vinculado : datosUsuario.uid

            commit('setReiniciarSoporteTecnico')
    
            const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico')

            //Obtener a los miembros de soporte tecnico
            referencia.on('child_added', async function (snapshot) 
            {

              const data = snapshot.val()

              data.idMiembro = snapshot.key

              //Verificar si el usuario es administrador
              if(administrador)
              {

                console.log(snapshot.key)
                console.log(data)

                commit('setMiembroSoporteTecnico', data)
              }
              else
              {
                //Verificar si los repartidores estan en la misma zona que el lider
                if(data['idVinculada'] === uid)
                {
      
                  console.log(snapshot.key)
                  console.log(data)
      
                  commit('setMiembroSoporteTecnico', data)
                }             
              }
                
            })

          } 
          catch (error) 
          {
            throw new error            
          }
        },
        getDetallesSoporteTecnico({ commit }, idSoporte)
        {
          try 
          {
            const db = firebase.database() 

            const referencia = db.ref('projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico/' + idSoporte)
    
            //Obtener el geo fire de repartidores
            referencia.on('value', function (snapshot) 
            {
              const data = snapshot.val()

              data.idSoporte = idSoporte

              commit('setDetallesSoporteTecnico', data)  
            })           
          }
          catch (error) 
          {
            throw new error            
          }
        },
        //////////////////////////////////////////////////////////////
        setNuevoEstatusSoporteTecnico({ commit }, miembro)
        {
          try 
          {
    
            const db = firebase.database() 
    
            //Actualizar informacion de repartidor
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/data/SoporteTecnico/${miembro.idSoporteTecnico}`)
            .update({ ['status']: miembro.estatus })
    
            //Actualizar members de repartidor
            db.ref(`projects/proj_8guEWRfqYWLLeqH4rGFWae/apps/app_6nqP5CU8TwZyreghXtCapT/members/${miembro.idSoporteTecnico}/customData`)
            .update({ ['status']: miembro.estatus })
    
            const alerta =
            {
              status: true,
              titulo: 'Estatus modificado',
              mensaje: 'Se ha cambiado el estatus a ' + miembro.nombreSoporteTecnico + '.',
              variant: 'success',
              toaster: 'b-toaster-top-right'
            }
      
            commit('setMensajeAlertaSoporteTecnico', alerta)     
            
            //commit('modificarEstatusSoporteTecnico', miembro.estatus)
    
          } 
          catch (error) 
          {
            const alerta =
            {
              status: false,
              titulo: 'Ha ocurrido un error',
              mensaje: 'ERROR: ' + error,
              variant: 'danger',
              toaster: 'b-toaster-top-right'
            }
    
            commit('setMensajeAlertaSoporteTecnico', alerta)         
          }
        },
    },
  
}